<template>
  <div class="list">
    <navbar :title="$t('dashboard.settlements')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <notifications></notifications>
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['subvention.person.personName']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.person") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['subventionsbeitrag.bezeichnung']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.subsidycontribution") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.subventionskanton"
                        :options="subventionskantone"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.subsidycanton')"
                      >
                        <span slot="no-options"
                          >{{ $t("global.nosubsidycantonfound") }}</span
                        >
                      </v-select>
                      <label>{{ $t("global.subsidycanton") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-antrag-von"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.antragVon"
                        field="antragVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.requestfrom") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-antrag-bis"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.antragBis"
                        field="antragBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.requestuntil") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-abrechnung-von"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.abrechnungVon"
                        field="abrechnungVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.settlementof") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-abrechnung-bis"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.abrechnungBis"
                        field="abrechnungBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.settlementuntil") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.periode"
                        :options="abrechnungsperioden"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.period')"
                      >
                        <span slot="no-options">{{ $t("global.noperiodsfound") }}</span>
                        <template v-slot:option="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                      </v-select>
                      <label>{{ $t("global.period") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.antragstatus"
                        :options="antragstati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.requeststatus')"
                      >
                        <span slot="no-options"
                          >{{ $t("global.norequeststatusfound") }}</span
                        >
                      </v-select>
                      <label>{{ $t("global.requeststatus") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.abrechnungsstatus"
                        :options="abrechnungsstati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.settlementstatus')"
                      >
                        <span slot="no-options"
                          >{{ $t("global.nosettlementstatusfound") }}</span
                        >
                      </v-select>
                      <label>{{ $t("global.settlementstatus") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betragVon"
                        class="form-control input"
                        type="number"
                        placeholder=" "
                      />
                      <label>{{ $t("global.amountfrom") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betragBis"
                        class="form-control input"
                        type="number"
                        placeholder=" "
                      />
                      <label>{{ $t("global.amountupto") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.subsidysettlementlist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>

            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex">
                  <div class="ml-4">
                    <button
                      class="btn btn-primary"
                      @click="exportToExcel(listData, 'subventionabrechnung')"
                    >
                      <span class="mr-2"
                        ><font-awesome-icon
                          icon="fa-regular fa-file-spreadsheet"
                      /></span>
                      {{ $t("global.exportlist")}}
                    </button>
                  </div>
                  <div class="ml-2">
                    <button
                      class="btn btn-primary"
                      @click="
                        exportToExcel(selectedObjects, 'subventionabrechnung')
                      "
                    >
                      <span class="mr-2"
                        ><font-awesome-icon
                          icon="fa-regular fa-file-spreadsheet"
                      /></span>
                      {{ $t("global.exportselected") }}
                    </button>
                  </div>
                  <div class="mr-4 float-right"></div>
                </div>
              </div>
            </div>

            <div class="row block belegliste mt-2">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDatumAbrechnung"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template #cell(nr)="data">
                      {{ data.index + 1 }}
                    </template>
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>
                    <template v-slot:cell(periode)="data">
                      <div v-if="data.value">
                        {{ data.value.bezeichnung }} ({{
                          data.value.startdatum
                        }}-{{ data.value.enddatum }})
                      </div>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">{{ $t("global.nofurtherdatafound") }}</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import DatePicker from "@/components/Datepicker";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import excelExport from "@/mixins/ExcelExport";
import numeral from "numeral";

import { LADE_AUSWAHLWERTE_SUBVENTION } from "@/store/subvention/actions.type";
import { LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG } from "@/store/subventionbeitrag/actions.type";

export default {
  name: "Subventionsabrechnungliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    DatePicker,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.subsidysettlements");
      },
    };
  },
  mixins: [page, dashboard, excelExport],
  data() {
    return {
      sortBy: "abrechnungsnummer",
      sortDesc: false,
      fields: [
        { key: "selected", label: "" },
        {
          key: "nr",
          searchable: false,
          sortable: true,
          label: this.$t("global.num"),
        },
        /*
        {
          key: "abrechnungsnummer",
          sortable: true,
          label: "Nr.",
          searchable: false,
        },
        */
        {
          key: "subvention.person.personName",
          sortable: true,
          label: this.$t("global.person"),
          searchable: true,
        },
        {
          key: "subventionsbeitrag.bezeichnung",
          sortable: true,
          label: this.$t("global.subsidycontribution"),
          searchable: true,
        },
        {
          key: "subvention.subventionskanton.bezeichnung",
          sortable: true,
          label: this.$t("global.subsidycanton"),
          searchable: false,
        },
        {
          key: "antrag",
          searchable: false,
          sortable: true,
          label: this.$t("global.request"),
        },
        {
          key: "abrechnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.settlement"),
        },
        {
          key: "periode",
          searchable: false,
          sortable: true,
          label: this.$t("global.period"),
        },
        {
          key: "antragstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.requeststatus"),
        },
        {
          key: "abrechnungsstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.settlementstatus"),
        },
        {
          key: "betrag",
          sortable: true,
          label: this.$t("global.amount"),
          searchable: false,
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
        },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      params.filterPerson = this.filters["subvention.person.personName"];
      params.filterSubventionsbeitrag =
        this.filters["subventionsbeitrag.bezeichnung"];
      params.filterPeriode = this.filters.periode;
      params.filterAntragstatus = this.filters.antragstatus;
      params.filterAbrechnungsstatus = this.filters.abrechnungsstatus;
      params.filterSubventionskanton = this.filters.subventionskanton;

      params.filterAntragVon = this.filters.antragVon;
      params.filterAntragBis = this.filters.antragBis;
      params.filterAbrechnungVon = this.filters.abrechnungVon;
      params.filterAbrechnungBis = this.filters.abrechnungBis;
      params.filterBetragVon = this.filters.betragVon;
      params.filterBetragBis = this.filters.betragBis;

      return params;
    },

    subventionskantone: {
      get() {
        return this.$store.state.subvention.subventionskantone;
      },
    },
    abrechnungsperioden: {
      get() {
        return this.$store.state.subventionbeitrag.abrechnungsperioden;
      },
    },
    antragstati: {
      get() {
        return this.$store.state.subventionbeitrag.antragsstati;
      },
    },
    abrechnungsstati: {
      get() {
        return this.$store.state.subventionbeitrag.abrechnungsstati;
      },
    },
  },

  created() {
    this.initFilter("bildungsportfolio-liste", "subvention/abrechnung/", true);
    //this.initFilter("bildungsportfolio-liste", "bildung/subvention/", true);
  },

  mounted() {
    this.$store.dispatch(`subvention/${LADE_AUSWAHLWERTE_SUBVENTION}`);
    this.$store.dispatch(
      `subventionbeitrag/${LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG}`
    );
    this.setHeaders("global", [
      "",
      "num",
      "person",
      "subsidycontribution",
      "subsidycanton",
      "request",
      "settlement",
      "period",
      "requeststatus",
      "settlementstatus",
      "amount",
    ]);
  },
  methods: {
    details(subventionsabrechnung) {
      this.$router.push({
        name: "subventionsabrechnung",
        params: {
          subventionsabrechnungid: subventionsabrechnung.id,
        },
      });
      /*
      this.$router.push({
        name: "subvention",
        params: { subventionid: subvention.id, anzeige: 0 },
      });
      */
    },
    sortDatumAbrechnung(datumA, datumB, key) {
      if (key != "antrag" && key != "abrechnung") {
        return false;
      } else {
        let a = null;
        let b = null;
        if (key.includes(".")) {
          a = this.resolveTermin(key, datumA);
          b = this.resolveTermin(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
            parseInt(a[2], 10) * 10000 +
            parseInt(a[1], 10) * 100 +
            parseInt(a[0]);
          b =
            parseInt(b[2], 10) * 10000 +
            parseInt(b[1], 10) * 100 +
            parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    },
    resolveTermin(path, obj = self, separator = ".") {
      var properties = Array.isArray(path) ? path : path.split(separator);
      return properties.reduce((prev, curr) => prev && prev[curr], obj);
    }
  },
};
</script>

<style>
</style>
