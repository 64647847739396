<template>
  <div>
    <div class="col-12 pl-0">
      <div :class="{ 'mt-30-px': !showNavbar }">
        <notifications></notifications>

        <navbar
          v-if="showNavbar"
          :title="$t('global.batixsoftware')"
          class=""
        ></navbar>

        <!-- HEAD -->
        <div class="row ml-0 px-5">
          <div v-if="showNavbar" class="col-xl-12">
            <div class="row">
              <div class="col-xl-12 block mb-3 pt-0">
                <head-menu
                  :col="12"
                  class="d-xl-flex"
                  :headline="
                    $t('global.finalgradeoverviewcourse') +
                    ' ' +
                    (this.endnotenpromotion.lehrgang.bezeichnung
                      ? this.endnotenpromotion.lehrgang.bezeichnung
                      : '')
                  "
                ></head-menu>
              </div>
            </div>
          </div>
        </div>

        <!-- REITER -->
        <div class="row" style="padding: 0 3rem 0 3.95rem !important">
          <div
            class="block block-kopf mr-1 clickable"
            v-for="(gruppe, index) in endnotenpromotion.sektionsgruppen"
            :key="gruppe[index]"
            @click="setSektionsgruppenIndex()"
            :style="
              sektionsgruppenIndex == index
                ? styleHeadlineSelected
                : styleHeadline
            "
          >
            <span class="col primary-headline-text p-0"
              ><router-link
                class="primary-headline-text nav-link p-0"
                :class="{ active: anzeige === 0 }"
                :to="{
                  name: 'Endnotenuebersicht',
                  params: {
                    lehrgangid: lehrgangid,
                    anzeige: index,
                  },
                }"
                >{{ gruppe.sektionsgruppe.bezeichnung }}</router-link
              ></span
            >
          </div>
        </div>
        <!-- BUTTONS -->
        <div
          class="bock block-kopf"
          style="padding: 0 3rem 0 3.95rem !important"
        >
          <div
            class="row block block-kopf d-flex justify-content-between"
            style="
              padding: 0.75rem 0 0.25rem 0 !important;
              border-radius: 0 !important;
            "
          >
            <div class="ml-4">
              <button
                class="btn btn-success"
                @click="speichern"
                @shortkey="speichern"
                v-if="berechtigungen.b_bildung_bildungsgang.delete"
              >
                <span>{{ $t("global.save") }}</span>
              </button>

              <button
                class="ml-3 btn btn-secondary"
                v-show="!this.bearbeitungGesperrt"
                :disabled="
                  this.endnotenpromotion.sektionsgruppen[
                    this.sektionsgruppenIndex
                  ].notenpromotionen.length == 0
                "
                @click="bearbeitungSperren"
              >
                <font-awesome-icon icon="fa-solid fa-lock" class="mr-2" />
                <span>{{ $t("global.blockediting") }}</span>
              </button>

              <button
                class="ml-3 btn btn-secondary"
                v-show="
                  this.bearbeitungGesperrt &&
                  !berechtigungen.b_bildung_bildungsgang.delete
                "
                disabled
              >
                <font-awesome-icon icon="fa-solid fa-lock" class="mr-2" />
                <span>{{ $t("global.editingblocked") }}</span>
              </button>

              <button
                class="ml-3 btn btn-secondary"
                v-show="
                  this.bearbeitungGesperrt &&
                  berechtigungen.b_bildung_bildungsgang.delete
                "
                :disabled="
                  this.endnotenpromotion.sektionsgruppen[
                    this.sektionsgruppenIndex
                  ].notenpromotionen.length == 0
                "
                @click="bearbeitungEntsperren"
              >
                <font-awesome-icon icon="fa-solid fa-unlock" class="mr-2" />
                <span>{{ $t("global.unlockediting") }}</span>
              </button>

              <button class="ml-3 btn btn-primary" @click="erstelleExcel">
                <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
                <em>{{ $t("global.createexcel") }}</em>
              </button>
            </div>

            <div class="mr-2">
              <button class="mr-3 btn btn-primary" @click="abbrechen">
                <span>{{ $t("global.cancel") }}</span>
              </button>
            </div>
          </div>
        </div>
        <!-- TABELLE -->
        <div class="row ml-0 px-5">
          <div
            class="col-xl-12 block belegliste text-break"
            style="width: 100% !important; border-radius: 0 0 10px 10px"
          >
            <div class="endnotenuebersichtTabelle" v-if="loaded">
              <b-table
                hover
                no-border-collapse
                responsive
                sticky-header="1000px"
                ref="selectableTable"
                small
                tbody-tr-class="item"
                :items="listData"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                sort-icon-left
                selectable
                select-mode="range"
                selected-variant=""
                style="text-align: center !important"
              >
                <template #cell(nr)="data">
                  {{ data.index + 1 }}
                </template>
                <template slot="top-row" slot-scope="{ fields }">
                  <td v-for="field in fields" :key="field.key">
                    <b-input
                      v-if="
                        field.key != 'selected' &&
                        field.searchable == true &&
                        field.list != true
                      "
                      size="sm"
                      v-model="filters[field.key]"
                      :placeholder="field.label"
                    />

                    <v-select
                      v-if="field.list == true"
                      v-model="filters[field.key]"
                      label="bezeichnung"
                      :options="promotionstati"
                      :placeholder="$t('global.promotionstatus')"
                      :reduce="(ps) => ps.id"
                    >
                      <span slot="no-options">{{
                        $t("global.promotionstatus")
                      }}</span>
                    </v-select>
                  </td>
                </template>
                <template
                  v-slot:cell(notenpromotion.konferenzentscheid.bezeichnung)="row"
                >
                  <div class="form-group m-0 p-0">
                    <v-select
                      v-model="row.item.notenpromotion.konferenzentscheid"
                      class="my-3"
                      label="bezeichnung"
                      :options="promotionstati"
                      :placeholder="$t('global.promotionstatus')"
                      @input="promotionsstatusChanged(row)"
                      :disabled="bearbeitungGesperrt"
                    >
                      <span slot="no-options">{{
                        $t("notification.taptosearch")
                      }}</span>
                    </v-select>
                  </div>
                </template>
                <template
                  #cell(show_details)="row"
                  class="d-flex align-items-center"
                >
                  <span class="d-flex justify-content-center">
                    <button
                      type="button"
                      size="sm"
                      @click="row.toggleDetails"
                      class="btn btn-primary btn-sm"
                    >
                      {{
                        row.detailsShowing
                          ? $t("global.hide")
                          : $t("global.show")
                      }}
                    </button>
                  </span>
                  <span class="d-flex justify-content-center p-1">
                    <font-awesome-icon
                      v-if="row.item.notenpromotion.bemerkung"
                      class="m-1"
                      icon="fa-regular fa-file-alt"
                    />
                  </span>
                </template>
                <template #row-details="row">
                  <b-card>
                    <span class="row mb-2">
                      <span sm="3" class="col-2 text-sm-right"
                        ><b>{{ $t("global.comment") }}: </b></span
                      >
                      <textarea
                        class="col-8 input-lg"
                        type="text"
                        :placeholder="$t('global.comment')"
                        v-model="row.item.notenpromotion.bemerkung"
                        :disabled="bearbeitungGesperrt"
                      />
                    </span>
                  </b-card>
                </template>

                <template
                  v-slot:custom-foot
                  v-if="!notendurchschnitt.length == 0"
                >
                  <b-tr
                    class="text-center"
                    style="background-color: #f5fbff; font-weight: bold"
                  >
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    >
                      {{ $t("global.average") }}:
                    </b-td>
                    <b-td
                      v-for="(durchschnitt, index) in notendurchschnitt"
                      :key="index"
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                      >{{ notendurchschnitt[index] }}</b-td
                    >
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                    <b-td
                      style="
                        border: none !important;
                        border-top: 2px solid #124862;
                      "
                    ></b-td>
                  </b-tr>
                </template>
              </b-table>
            </div>
            <infinite-loading
              v-if="!loaded"
              :identifier="infiniteId"
              @infinite="infiniteHandler"
            >
              <div slot="no-results"></div>
            </infinite-loading>
            <div class="text-center" v-if="noEntries">
              {{ $t("global.nodataavailable") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";
import dashboard from "@/mixins/Dashboard";

import server from "@/server";

import FileDownload from "js-file-download";

import { LADE_AUSWAHLWERTE_NOTEN } from "@/store/noten/actions.type";

export default {
  name: "Endnotenuebersicht",
  components: {
    HeadMenu,
    Navbar,
  },

  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.finalgradeoverview");
      },
    };
  },
  mixins: [page, ChangeWatcher, dashboard],
  props: {
    lehrgangid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sektionenKeys: [],
      sektionsfelderKeys: [],
      endnotenpromotion: {
        lehrgang: {
          bezeichnung: "",
        },
        sektionsgruppen: [
          {
            id: "",
            bezeichnung: "",
            sektionsgruppe: { id: "", bezeichnung: "" },
            sektionen: [{ sektionsfelder: [{ bezeichnung: "", id: "" }] }],
            notenpromotionen: [
              {
                abgeschlossen: "",
                promotionsstatus: "",
                konferenzentscheid: "",
                bemerkung: "",
                abschluss: "",
                anmeldetyp: "",
                person: {
                  nachname: "",
                  vorname: "",
                },
                sektionen: [
                  {
                    sektionsnote: "",
                    sektionsbezeichnung: "",
                    sektionfelder: [
                      {
                        bezeichnung: "",
                        note: "",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      sektionsgruppenIndex: 0,
      loaded: null,
      noEntries: null,
      bearbeitungGesperrt: false,
      notendurchschnitt: [],
      notenfelder: [],
      fields: [],
      defaultFields: [
        "nr",
        {
          key: "notenpromotion.person.nachname",
          searchable: true,
          //label: this.$t("global.surname"),
          label: "Nachname",
          sortable: true,
        },
        {
          key: "notenpromotion.person.vorname",
          searchable: true,
          //label: this.$t("global.firstname"),
          label: "Vorname",
          sortable: true,
        },
        {
          key: "notenpromotion.anmeldetyp.bezeichnung",
          //label: this.$t("dashboard.type"),
          label: "Typ",
          sortable: true,
        },
        {
          key: "notenpromotion.abschluss.bezeichnung",
          //label: this.$t("global.degree"),
          label: "Abschluss",
          sortable: true,
        },
        {
          key: "notenpromotion.promotionsstatus.bezeichnung",
          list: true,
          searchable: true,
          sortable: true,
          //label: this.$t("global.promotionstatus"),
          label: "Promotionsstatus",
          tdClass: (value, key, item) => {
            return "table-" + this.setPromotionsstatusColor(item);
          },
        },
        {
          key: "notenpromotion.konferenzentscheid.bezeichnung",
          //label: this.$t("global.conferencedecision"),
          label: "Konferenzentscheid",
          sortable: true,
        },
        {
          key: "show_details",
          //label: this.$t("global.comment"),
          label: "Bemerkung",
        },
      ],

      page: 0,
      anzahlProPage: 50,
      sortBy: "notenpromotion.person.nachname",
      sortDesc: false,
      styleHeadlineSelected: {
        filter: "none",
      },
      styleHeadline: {
        filter: "brightness(0.9)",
      },
    };
  },
  computed: {
    promotionstati: {
      get() {
        return this.$store.state.noten.promotionstati;
      },
    },

    //TODO: Filter im Backend
    queryParams: function () {
      var params = {
        lehrgangid: this.$route.params.lehrgangid,
      };

      params.filterNachname = this.setQueryParam(
        this.filters["notenpromotion.person.nachname"]
      );
      params.filterVorname = this.setQueryParam(
        this.filters["notenpromotion.person.vorname"]
      );
      params.promotionsstatus = this.setQueryParam(
        this.filters["notenpromotion.promotionsstatus.bezeichnung"]
      );

      return params;
    },

    getCellKonferenzentscheid() {
      return `notenpromotion.konferenzentscheid`;
    },
  },
  watch: {},
  created() {
    this.$store.dispatch(`noten/${LADE_AUSWAHLWERTE_NOTEN}`);
    this.getEndnotenpromotion();
    this.checkBearbeitungGesperrt();
  },
  mounted() {
    /*
    this.setHeaders("global", [
      "surname",
      "firstname",
      "",
      "degree",
      "promotionstatus",
      "conferencedecision",
      "comment",
    ]);

    this.setHeaders("dashboard", [
      "",
      "",
      "type",
    ]);*/
  },
  methods: {
    //Holt index der Sektionsgruppe anhand Parameter
    setSektionsgruppenIndex() {
      if (this.sektionsgruppenIndex != this.$route.params.anzeige) {
        this.loaded = null;
        this.sektionsgruppenIndex = this.$route.params.anzeige;
        this.setListData(this.sektionsgruppenIndex);
        this.initializeNotenfelder();
      }
    },
    //Holt Endnotenpromotion und setzt listData und Noten-Fields
    getEndnotenpromotion() {
      this.sektionsgruppenIndex = this.$route.params.anzeige;
      Api.get("noten/endnotenpromotion/", {
        params: { lehrgangid: this.lehrgangid },
      })
        .then((response) => {
          this.endnotenpromotion = response.data;
          this.setListData(this.sektionsgruppenIndex);
          this.initializeNotenfelder();
        })
        .catch((e) => {
          /* this.$notify(apiErrorToAlert(e)); */
          this.$notify({
            type: "error",
            title: this.$t("notification.actionfailed"),
            text: this.$t(
              "Notenstruktur für diese Veranstaltung ist nicht gesetzt. Bitte einpflegen."
            ),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //Setzt listData anhand des aktuell gewählten Reiters
    setListData(index) {
      this.listData =
        this.endnotenpromotion.sektionsgruppen[index].notenpromotionen;
      this.loaded = true;
      if (
        this.endnotenpromotion.sektionsgruppen[index].notenpromotionen.length ==
        0
      ) {
        this.noEntries = true;
      } else {
        this.noEntries = false;
      }
    },
    //Überprüft, ob die Bearbeitung der Endnotenpromotion abgeschlossen ist und sperrt diese entsprechend
    checkBearbeitungGesperrt() {
      let pos = this.endnotenpromotion.sektionsgruppen[
        this.sektionsgruppenIndex
      ].notenpromotionen.findIndex(
        (promotion) => promotion.abgeschlossen == true
      );

      if (pos != -1) {
        this.bearbeitungGesperrt = true;
      }
    },
    //Fügt dem Array notenfelder die Felder anhand der Sektionsfelder der aktuellen Sektionsgruppe zu.
    initializeNotenfelder() {
      this.notenfelder = [];
      if (
        this.endnotenpromotion?.sektionsgruppen[this.sektionsgruppenIndex]
          ?.sektionen[0]?.sektionsfelder
      ) {
        this.endnotenpromotion.sektionsgruppen[
          this.sektionsgruppenIndex
        ].sektionen.forEach((sektion, sektIndex) => {
          //Sektionsnote
          this.notenfelder.push({
            key: "sektionen[" + sektIndex + "].note",
            sortable: true,
            bezeichnung: sektion.bezeichnung.toString(),
            label: "∅ " + sektion.bezeichnung.toString(),
            formatter: (value, key, item) => {
              if (item.sektionen[sektIndex]?.dispensiert == true) {
                return "Dispensiert";
              } else if (item.sektionen[sektIndex]?.textwahlwert) {
                return item.sektionen[sektIndex]?.textwahlwert;
              } else if (!item.sektionen[sektIndex]?.note) {
                return "-";
              } else {
                return value;
              }
            },
            tdClass: (value) => {
              if (value < 4 && value > 0) return "table-warning";
            },
          });
          sektion.sektionsfelder.forEach((feld, feldIndex) => {
            //sektionsfeldnote
            this.notenfelder.push({
              key:
                "sektionen[" +
                sektIndex +
                "].sektionsfelder[" +
                feldIndex +
                "].note",
              sortable: true,
              bezeichnung: feld.bezeichnung.toString(),
              label: feld.bezeichnung.toString(),
              formatter: (value, key, item) => {
                if (
                  item.sektionen[sektIndex]?.sektionsfelder[feldIndex]
                    ?.dispensiert == true
                ) {
                  return "Dispensiert";
                } else if (
                  item.sektionen[sektIndex]?.sektionsfelder[feldIndex]
                    ?.textwahlwert
                ) {
                  return item.sektionen[sektIndex]?.sektionsfelder[feldIndex]
                    ?.textwahlwert;
                } else if (
                  !item.sektionen[sektIndex]?.sektionsfelder[feldIndex]?.note
                ) {
                  return "-";
                } else {
                  return value;
                }
              },
              tdClass: (value) => {
                if (value < 4 && value > 0) return "table-warning";
              },
            });
          });
        });
        this.initializeFields();
      }
    },
    //Fügt den Fields die Felder aus dem Array notenfelder hinzu, damit diese in der Tabelle angezeigt werden
    initializeFields() {
      this.fields = this.defaultFields.slice();

      for (let i = 0; i < this.notenfelder?.length; i++) {
        this.fields.splice(i + 5, 0, this.notenfelder[i]);
      }
      this.sortNotenTeilnehmer();
    },

    //Sortiert die Noten (Sektionen & Sektionsfelder) der Teilnehmer anhand des Notenfeld-Arrays
    sortNotenTeilnehmer() {
      var sektionenKeys = [];
      var sektionsfelderKeys = [];
      //Erstelle Arrays mit den Keys in der korrekten Reihenfolge aus der Vorlage. Nach diesen Keys werden die Daten der Teilnehmer sortiert
      this.endnotenpromotion.sektionsgruppen[
        this.sektionsgruppenIndex
      ].sektionen.forEach((sektion) => {
        if (sektion.bezeichnung) {
          sektionenKeys.push(sektion.bezeichnung);
        }
        sektion.sektionsfelder.forEach((feld) => {
          if (feld.bezeichnung) {
            sektionsfelderKeys.push(feld.bezeichnung);
          }
        });
      });

      this.endnotenpromotion.sektionsgruppen[
        this.sektionsgruppenIndex
      ].notenpromotionen.forEach((promotion) => {
        promotion.sektionen.sort(
          (a, b) =>
            sektionenKeys.indexOf(a.bezeichnung) -
            sektionenKeys.indexOf(b.bezeichnung)
        );
        promotion.sektionen.forEach((sektion) => {
          if (sektion?.sektionsfelder) {
            sektion.sektionsfelder.sort(
              (a, b) =>
                sektionsfelderKeys.indexOf(a.bezeichnung) -
                sektionsfelderKeys.indexOf(b.bezeichnung)
            );
          }
        });
      });
      this.durchschnittBerechnen();
    },
    //Setzt Hintergrundfarbe der Spalte Promotionsstatus
    setPromotionsstatusColor(item) {
      if (item.notenpromotion?.promotionsstatus) {
        if (item.notenpromotion.promotionsstatus.bezeichnung == "promoviert") {
          return "success";
        }
        if (
          item.notenpromotion.promotionsstatus.bezeichnung ==
          "bedingt promoviert"
        ) {
          return "warning";
        }
        if (
          item.notenpromotion.promotionsstatus.bezeichnung == "nicht promoviert"
        ) {
          return "danger";
        } else {
          return "primary";
        }
      }
    },
    //Berechnet den Durchschnitt aller Teilnehmer pro Spalte in der Tabelle
    durchschnittBerechnen() {
      this.notendurchschnitt = [];

      var sektionNoten = [];

      this.endnotenpromotion.sektionsgruppen[
        this.sektionsgruppenIndex
      ].notenpromotionen.forEach((promotion, promIndex) => {
        this.endnotenpromotion.sektionsgruppen[
          this.sektionsgruppenIndex
        ].notenpromotionen[promIndex].sektionen.forEach(
          (sektion, sektIndex) => {
            if (sektion.note) {
              if (sektionNoten[sektIndex]) {
                if (isNaN(sektion.note) == false) {
                  sektionNoten[sektIndex].note += parseFloat(sektion.note);
                  sektionNoten[sektIndex].count++;
                }
              } else {
                sektionNoten.push({
                  feldNoten: [],
                  note: parseFloat(sektion.note),
                  count: 1,
                  bezeichnung: sektion.bezeichnung,
                });
              }
            } else {
              if (!sektionNoten[sektIndex]) {
                sektionNoten.push({
                  feldNoten: [],
                  note: 0,
                  count: 0,
                  bezeichnung: sektion.bezeichnung,
                });
              }
            }
            this.endnotenpromotion.sektionsgruppen[
              this.sektionsgruppenIndex
            ].notenpromotionen[promIndex].sektionen[
              sektIndex
            ].sektionsfelder.forEach((feld, feldIndex) => {
              if (feld.note) {
                if (sektionNoten[sektIndex]?.feldNoten[feldIndex]) {
                  if (isNaN(feld.note) == false) {
                    sektionNoten[sektIndex].feldNoten[feldIndex].note +=
                      parseFloat(feld.note);
                    sektionNoten[sektIndex].feldNoten[feldIndex].count++;
                  }
                } else {
                  sektionNoten[sektIndex].feldNoten.push({
                    note: parseFloat(feld.note),
                    count: 1,
                    bezeichnung: feld.bezeichnung,
                  });
                }
              } else {
                if (!sektionNoten[sektIndex]?.feldNoten[feldIndex]) {
                  sektionNoten[sektIndex].feldNoten.push({
                    note: 0,
                    count: 0,
                    bezeichnung: feld.bezeichnung,
                  });
                }
              }
            });
          }
        );
      });
      sektionNoten.forEach((sektionNote) => {
        if (
          sektionNote.note &&
          sektionNote.note != 0 &&
          !isNaN(sektionNote.note)
        ) {
          this.notendurchschnitt.push(
            (sektionNote.note / sektionNote.count + Number.EPSILON).toFixed(1)
          );
        } else {
          this.notendurchschnitt.push("-");
        }
        sektionNote.feldNoten.forEach((feldNote) => {
          if (feldNote.note && feldNote.note != 0 && !isNaN(feldNote.note)) {
            this.notendurchschnitt.push(
              (feldNote.note / feldNote.count + Number.EPSILON).toFixed(1)
            );
          } else {
            this.notendurchschnitt.push("-");
          }
        });
      });
    },
    //Schliesst alle Notenpromotionen ab und verhindert weitere Bearbeitung
    bearbeitungSperren() {
      this.endnotenpromotion.sektionsgruppen[
        this.sektionsgruppenIndex
      ].notenpromotionen.forEach(
        (promotion) => (promotion.abgeschlossen = true)
      );
      this.bearbeitungGesperrt = true;
    },
    //Öffnet alle Notenpromotionen wieder und aktiviert Bearbeitung
    bearbeitungEntsperren() {
      this.endnotenpromotion.sektionsgruppen[
        this.sektionsgruppenIndex
      ].notenpromotionen.forEach(
        (promotion) => (promotion.abgeschlossen = false)
      );
      this.bearbeitungGesperrt = false;
    },

    erstelleExcel() {
      Api.post(
        "/noten/endnotenpromotion/export/",
        {
          lehrgang: {
            id: this.endnotenpromotion.lehrgang.id,
            bezeichnung: this.endnotenpromotion.lehrgang.bezeichnung,
          },
          felder: this.notenfelder,
          rows: this.endnotenpromotion.sektionsgruppen[
            this.sektionsgruppenIndex
          ].notenpromotionen,
        },
        { responseType: "blob" } //SWE: notwendig damit OutputExcel nicht beschädigt wird
      )
        .then((response) => {
          this.excelFile = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileDownload(
            this.excelFile,
            `${
              this.endnotenpromotion.lehrgang.bezeichnung
            }-${new Date().toLocaleDateString()}.xlsx`
          );
          this.loading = false;
        })
        .catch(() => {
          setTimeout(() => {
            this.$notify({
              type: "error",
              title: this.$t("notification.actionfailed"),
              text: this.$t("notification.errorwhilecreatingtheexcelfile"),
              duration: 7000,
            });
          }, 2000);
          this.loading = false;
        });
    },
    abbrechen() {
      this.$router.push({
        name: "bildungsgang",
        params: { lehrgangid: this.lehrgangid, anzeige: 0 },
      });
    },
    speichern() {
      if (this.loading) return;

      if (
        this.endnotenpromotion.sektionsgruppen[this.sektionsgruppenIndex]
          .notenpromotionen.length > 0
      ) {
        var json =
          this.endnotenpromotion.sektionsgruppen[this.sektionsgruppenIndex]
            .notenpromotionen;
        Api.put("noten/endnotenpromotion/", json, {
          params: {
            lehrgangid: this.lehrgangid,
          },
        })
          .then((response) => {
            this.getEndnotenpromotion();
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.finalgradeoverviewsavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("notification.actionfailed"),
          duration: 5000,
          text: this.$t(
            "notification.nogradecalculationavailableaddgradetocourseandcalculate"
          ),
        });
      }
    },
  },
};
</script>

<style>
.endnotenuebersichtTabelle .table > tbody > tr.b-table-row-selected {
  filter: brightness(95%);
  /*position: relative;*/
  z-index: 2;
}
.endnotenuebersichtTabelle .table > thead > tr > th[aria-sort="ascending"],
.endnotenuebersichtTabelle .table > thead > tr > th[aria-sort="descending"] {
  background-color: #a7c1cc;
}
.endnotenuebersichtTabelle .table > thead > tr > th {
  cursor: pointer;
}
.endnotenuebersichtTabelle .table > thead > tr > th:first-child {
  min-width: 31px;
}
.endnotenuebersichtTabelle .table > thead > tr > th:nth-child(6) {
  min-width: 45px;
}
.endnotenuebersichtTabelle
  .b-table-sticky-header
  > .table.b-table
  > thead
  > tr
  > th,
.endnotenuebersichtTabelle .b-table-sticky-header > .table.b-table > thead > tr,
.endnotenuebersichtTabelle .b-table-sticky-header > .table.b-table > thead {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
  z-index: 10;
  background: #b5cfdc;
}
.endnotenuebersichtTabelle .b-table-sticky-header {
  scrollbar-width: thin;
  scrollbar-color: #b5cfdc #f5fbff;
}
.endnotenuebersichtTabelle .table {
  width: auto !important;
  overflow-x: scroll;
}
.endnotenuebersichtTabelle .table td {
  min-width: 100px;
}
</style>
