<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.kuerzel"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.veranstaltungstatus"
                        :options="veranstaltungsstati"
                        :reduce="(vsl) => vsl.id"
                        label="bezeichnung"
                        :placeholder="$t('global.statusclass')"
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusofclassesfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="termin-dozent"
                      :person="filters.verantwortlicher"
                      :label="$t('global.Responsible')"
                      :allowOpen="false"
                      :rollen="filterRollen"
                      :editable="true"
                      @confirmed="setFilterVerantwortlicher"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.semester"
                        :options="periode"
                        :reduce="(s) => s.id"
                        :placeholder="$t('global.period')"
                      >
                        <span slot="no-options">{{
                          $t("global.noperiodsfound")
                        }}</span>
                        <template v-slot:option="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                      </v-select>
                      <label>{{ $t("global.period") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.periodenno"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("dashboard.semester") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.classlist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <massenmutation-button
                      :mutatableFields="this.mutatableFields"
                      :mutatableValues="this.mutatableValues"
                      :mutatableLabels="this.mutatableLabels"
                      :mutationPfad="this.mutationPfad"
                      :selectedIds="this.selectedIds"
                      @mutated="refresh"
                    />
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.b_bildung_klasse.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="openKlasse"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(periode)="data">
                      <div v-if="data.value">
                        {{ data.value.bezeichnung }} ({{
                          data.value.startdatum
                        }}-{{ data.value.enddatum }})
                      </div>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>

            <loeschen-modal
              id="klasse-loeschen-modal"
              @confirmed="loeschen"
              :multiple="true"
            />

            <messagebox-count
              :headerText="$t('global.eventsdeleted')"
              id="count-delete-alert-msgbox"
              :ok="true"
              :cancel="false"
              :okText="$t('global.ok')"
              :cancelText="$t('global.cancel')"
            />
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

import store from "@/store";
import Api from "@/Api";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import MassenmutationButton from "@/components/Buttons/MassenmutationButton";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

export default {
  name: "Klassenliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    SingleSelectPerson,
    MassenmutationButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.classes");
      },
    };
  },
  mixins: [page, dashboard],
  props: {},
  store,
  data() {
    return {
      verantwortliche: [],
      mutatableFields: ["Status", "Verantwortlicher", "Periode"],
      mutatableValues: [[], [], []],
      mutatableLabels: ["bezeichnung", "personName", "bezeichnung"],
      mutationPfad: "veranstaltungen/",
      filterRollen: this.$CONST("ROLLEN").DOZENTEN,
      fields: [
        { key: "selected", label: "" },
        {
          key: "kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "periode",
          sortable: true,
          label: this.$t("global.period"),
        },
        {
          key: "anmeldungen",
          label: this.$t("global.registrations"),
          tdClass: "text-center",
          thClass: "text-center",
          searchable: false,
        },
        {
          key: "verantwortlicher.personName",
          sortable: true,
          label: this.$t("global.responsible"),
        },

        {
          key: "veranstaltungsstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
        veranstaltungstyp: this.$CONST("VERANSTALTUNGSTYPEN").STUDIES,
      };

      params.filterBezeichnung = this.setQueryParam(this.filters.bezeichnung);
      params.filterKurzbezeichnung = this.setQueryParam(this.filters.kuerzel);
      params.status = this.setQueryParam(this.filters.veranstaltungstatus);
      params.verantwortlicher = this.setQueryParam(
        this.filters.verantwortlicher?.id
      );
      params.periode = this.setQueryParam(this.filters.semester);
      params.periodeNo = this.setQueryParam(this.filters.periodenno);

      params.filterPBeZ = this.setQueryParam(this.filters["periode"]);
      params.filterVName = this.setQueryParam(
        this.filters["verantwortlicher.personName"]
      );
      params.filterVSBeZ = this.setQueryParam(
        this.filters["veranstaltungsstatus.bezeichnung"]
      );

      params.filterCount = this.setQueryParam(this.filters.count);

      return params;
    },

    veranstaltungsstati: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsstati;
      },
    },
    periode: {
      get() {
        return this.$store.state.veranstaltung.perioden;
      },
    },
  },
  created() {
    if (this.veranstaltungsstati.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);

    this.initFilter("klassen-liste", "veranstaltungen/", true);

    Api.get("geschaeftspartner/", {
      params: {
        rollen: this.$CONST("ROLLEN").DOZENTEN.join(),
        count: 1000,
      },
    }).then((response) => {
      this.verantwortliche = response.data.sort(function (a, b) {
        return a.personName > b.personName;
      });
      /*this.mutatableValues[1] = Object.assign({}, this.verantwortliche);
        console.log(this.verantwortliche);

        this.mutatableValues[0] = Object.assign({}, this.lehrgangstati);
    console.log(this.lehrgangstati);*/

      this.mutatableValues[0].push(this.veranstaltungsstati);
      this.mutatableValues[1].push(this.verantwortliche);
      this.mutatableValues[2].push(this.periode);
    });
  },
  mounted() {
    this.setHeaders("global", [
      "",
      "shortdesignation",
      "designation",
      "period",
      "registrations",
      "responsible",
      "status",
    ]);
  },
  methods: {
    refresh() {
      this.resetLoading(this);
    },
    openKlasse(klasse) {
      this.$router.push({
        name: "klasse",
        params: {
          klassenid: klasse.id,
          anzeige: 0,
        },
      });
    },

    setFilterVerantwortlicher(person) {
      this.$set(this.filters, "verantwortlicher", person);
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "klasse-loeschen-modal");
    },

    loeschen() {
      this.delete("veranstaltungen/", this.selectedIds);
    },
  },
};
</script>

<style>
</style>
