<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-xl">
      <div class="modal-content" style="border: 1px solid black">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">Dozenteneinsatz suchen</h4>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-xl-4">
              <div class="form-group">
                <input
                  ref="editName"
                  v-model.trim="name"
                  class="form-control"
                  placeholder=" "
                  @keyup.enter="resetLoading()"
                />
                <label>Dozent</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 block modal-liste br-t-l-0">
              <div style="height: 300px !important">
                <b-table
                  responsive
                  sticky-header
                  selectable
                  select-mode="single"
                  @row-dblclicked="rowDblClick"
                  @row-selected="onRowSelected"
                  tbody-tr-class="item"
                  :items="dozenteneinsaetze"
                  :fields="fields"
                  :busy="isBusy"
                  :show-empty="loaded"
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center text-primary my-2">
                      Keine Daten gefunden.
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="abbrechen">
            Abbrechen
          </button>
          <button
            type="button"
            class="btn btn-danger mr-2"
            @click="entfernen"
            v-if="remove"
          >
            {{ $t("global.remove") }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="uebernehmen"
            :disabled="selected.length == 0"
          >
            Übernehmen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import ModalCloseButton from "@/components/global/ModalCloseButton";
import { BSpinner } from "bootstrap-vue";

export default {
  name: "DozenteneinsatzSelectModal",
  components: { ModalCloseButton, BSpinner },
  props: {
    id: {
      type: String,
      required: false,
      default: "dozenteneinsatz-modal",
    },
    adressTypen: {
      type: Array,
      required: false,
    },
    filterRollen: {
      type: Array,
      required: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBusy: false,
      loaded: false,
      anzahlProPage: 99,
      dozenteneinsaetze: [],
      fields: [
        {
          key: "dozentenbuchung.person.name",
          sortable: true,
          label: "Nachname",
        },
        {
          key: "dozentenbuchung.person.vorname",
          sortable: true,
          label: "Vorname",
        },
        {
          key: "dozentenbuchung.veranstaltung.bezeichnung",
          sortable: true,
          label: this.$t("global.event"),
        },
        {
          key: "dozentenbuchung.veranstaltung.kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "termin.von",
          sortable: true,
          label: "Termin",
        },
        {
          key: "termin.von_uhrzeit",
          sortable: true,
          label: this.$t("global.from"),
        },
        {
          key: "termin.bis_uhrzeit",
          sortable: true,
          label: this.$t("global.till"),
        },
      ],
      vorname: null,
      name: "",
      zip: null,
      selected: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      params.adresstyp = this.adressTypen ? this.adressTypen.join() : null;
      params.rollen = this.filterRollen ? this.filterRollen.join() : null;
      params.name = this.name;
      params.suche = this.vorname;
      params.zip = this.zip;

      return params;
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("openDozenteneinsatzModal", (id) => {
      if (id == this.id && !this.shown) this.openModal();
    });
  },
  methods: {
    openModal() {
      this.vorname = null;
      this.name = null;
      this.zip = null;
      this.dozenteneinsaetze = [];

      this.shown = true;

      let that = this;

      $("#" + this.id).on("shown.bs.modal", function () {
        that.$refs.editName.focus();
      });

      $("#" + this.id).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    onRowSelected(items) {
      this.selected = items;
    },

    rowDblClick(item) {
      this.selected = [item];
      this.uebernehmen();
    },

    resetLoading() {
      this.isBusy = true;

      Api.get("dozenteneinsatz/", {
        params: this.queryParams,
      })
        .then((response) => {
          //response.data.shift();
          this.dozenteneinsaetze = response.data;
        })
        .finally(() => {
          this.isBusy = false;
          this.loaded = true;
        });
    },

    uebernehmen() {
      if (this.selected == null || this.selected.length == 0) return;
      this.closeModal();
      this.$emit("confirmed", this.selected);
    },

    abbrechen() {
      this.closeModal();
    },
    entfernen() {},
  },
};
</script>

<style>
</style>
