<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" title="ABB Technikerschule"></navbar>
    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.standardevent')"
            ></head-menu>
            <div class="row mt-2">
              <div class="ml-4 mr-2">
                <button
                  v-if="editable"
                  v-shortkey.once="['ctrl', 's']"
                  class="btn btn-success"
                  @click="speichern"
                  @shortkey="speichern"
                >
                  {{ $t("global.save") }}
                </button>
              </div>

              <div class="mr-2">
                <button class="btn btn-primary" @click="abbrechen">
                  {{ $t("global.cancel") }}
                </button>
              </div>
              <div class="mr-2">
                <button
                  v-if="editable"
                  v-shortkey.once="['ctrl', 's']"
                  class="btn btn-success"
                  @click="oeffneDokumentModal"
                >
                  {{ $t("global.newmedium") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- START Linke Seite -->
      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Stammdaten -->
        <container-headline
          :headline="$t('global.masterdata')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model.trim="screenevent.bezeichnung"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label>{{ $t("global.designation") }}</label>
                  </div>
                </div>
                <div class="col-xl-3">
                  <div class="form-group">
                    <input
                      v-model.trim="screenevent.slideTimer"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label>{{ $t("global.timer") }}</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <input
                      v-model.trim="screenevent.iframe"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label>{{ $t("global.Link") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Stammdaten -->
      </div>
      <!-- ENDE linke Seite -->
      <!-- START Rechte Seite -->
      <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Raum -->
        <div>
          <container-headline
            :headline="$t('global.media')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <!-- Anzeige Medien -->

                <div
                  class="col-xl-12 mb-3"
                  v-for="items in screenevent.medien"
                  :key="items.Dateiname"
                >
                  <div class="row">
                    <div
                      class="row"
                      v-if="items.Dateiname.includes('mp4')"
                      style="padding-left: 15px"
                    >
                      <video
                        height="50"
                        width="75"
                        style="object-fit: cover; border-radius: 5px"
                      >
                        <source
                          :src="baseURL + items.Dateiname + 'mp4#t=0.1'"
                          type="video/mp4"
                        />
                      </video>
                      <div
                        style="
                          margin: auto;
                          padding-left: 15px;
                          min-width: 65px;
                        "
                      >
                        {{ $t("global.video") }}
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="!items.Dateiname.includes('mp4')"
                      style="padding-left: 15px"
                    >
                      <img
                        height="50"
                        width="75"
                        style="object-fit: cover; border-radius: 5px"
                        :src="baseURL + items.Dateiname"
                      />
                      <div
                        style="
                          margin: auto;
                          padding-left: 15px;
                          min-width: 65px;
                        "
                      >
                        {{ $t("global.image") }}
                      </div>
                    </div>
                    <div class="col-xl-8" style="margin: auto">
                      {{ items.titel }}
                    </div>
                    <button
                      v-if="screenevent.medien"
                      class="btn btn-danger btn-sm"
                      @click="deleteMedium(items.ID)"
                      style="
                        width: 30px;
                        height: 30px;
                        margin: auto 0 auto auto;
                      "
                    >
                      <font-awesome-icon icon="fa-solid fa-ban" size="sm" />
                    </button>
                  </div>
                </div>

                <slides-upload
                  :evid="this.id"
                  id="slides-upload"
                  @bildUploaded="ladeGeschaeftspartner"
                ></slides-upload>
                <!-- Ende Medien -->
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Raum -->
      </div>
      <!-- ENDE Rechte Seite -->
    </div>
    <div id="modal-loeschen" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4>
              <font-awesome-icon
                icon="fa-regular fa-warning"
                class="text-warning mr-2"
              />
              {{ $t("global.deleteroom") }}
            </h4>
          </div>
          <div class="modal-body">
            {{ $t("notification.doyouwantdeleteroom") }}
          </div>
          <div class="modal-footer">
            <button class="btn btn-default" @click="schliesseLoeschenModal">
              {{ $t("global.cancel") }}
            </button>
            <button class="btn btn-primary" @click="loeschen">
              {{ $t("global.delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="loading"></loading-overlay>

    <change-watcher-alert />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";
import SlidesUpload from "@/components/Geschaeftspartner/SlidesUploads";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";

import ChangeWatcher from "@/mixins/ChangeWatcher";
import ChangeWatcherAlert from "@/components/global/ChangeWatcherAlert.vue";

export default {
  name: "Standardevent",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LoadingOverlay,
    ChangeWatcherAlert,
    SlidesUpload,
  },
  metaInfo() {
    const bezeichnung = this.screenevent.bezeichnung;
    return {
      titleTemplate: () => {
        if (!this.id) {
          return "Neues Event";
        }
        return `Event ${bezeichnung}`;
      },
    };
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      baseURL: "",
      screenevent: {
        bezeichnung: null,
        kurzbezeichnung: null,
        bemerkung: null,
        gebaeude: null,
        raumgruppe: null,
        geschoss: null,
        flaeche: null,
        sitzplaetze: null,
        bildschirme: [],
      },
      bildschirmListe: [],
      raumgruppenliste: [],
      loading: false,
      editable: false,
      mp4: "mp4",
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.screenevent) {
        return `Standardevent ${this.screenevent}`;
      } else {
        return "Neues Event";
      }
    },
  },
  created() {
    this.baseURL = this.$CONST("CONFIG").SERVERURL.slice(0, -1);

    Api.get("bildschirmloesung/", { params: { id: this.id } }).then(
      (response) => {
        this.initializeBildschrimloesung(response.data);
      }
    );
    if (this.bildschirmListe.length == 0) this.ladeBildschirme();
  },
  mounted: function () {},
  methods: {
    ladeGeschaeftspartner() {
      this.$emit("bildUpdated", this.id);
    },

    oeffneDokumentModal() {
      $("#slides-upload").modal("show");
    },
    abbrechen() {
      this.$router.push({ name: "bildschirm-planer" });
    },
    initializeBildschrimloesung(screenevent) {
      if (screenevent) {
        this.screenevent = screenevent;
        //this.screenevent.bildschirme = screenevent.bildschirme
        // abfangen wenn gebaeude/raumgrupppe = null ist, raum sollte eigentlich nicht null sein
        this.initChangeWatcher(this.screenevent);
      } else {
        console.error("screenevent ist null");
      }
      this.editable = this.berechtigungen.m_ressourcen.update;
      this.initChangeWatcher(this.screenevent);
      /*
      if (raum) {
        this.raum = raum;
        this.bezeichnung = raum.bezeichnung;
        this.kurzbezeichnung = raum.kurzbezeichnung;
        this.bemerkung = raum.bemerkung;
        this.gebaeude = raum.gebaeude;
        this.raumgruppe = raum.raumgruppe;
        this.geschoss = raum.geschoss;
        this.flaeche = raum.flaeche;
        this.sitzplaetze = raum.sitzplaetze;

        // this.initChangeWatcher(this.termin); Muss noch auf Single Object umgebaut werden
      } else {
        console.error("raum ist null");
      }


      */
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.screenevent);

      if (!this.id) {
        Api.post("bildschirmloesung/", json)
          .then((response) => {
            this.initializeBildschrimloesung(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.eventsuccessfullysaved"),
            });

            this.$router.replace({
              name: "standardevent",
              params: { id: response.data.id },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("bildschirmloesung/", json, { params: { id: this.id } })
          .then((response) => {
            this.initializeBildschrimloesung(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.eventsuccessfullysaved"),
            });
            Api.get("bildschirmloesung/", { params: { id: this.id } }).then(
              (response) => {
                this.initializeBildschrimloesung(response.data);
              }
            );
            if (this.bildschirmListe.length == 0) this.ladeBildschirme();
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    loeschen() {
      Api.delete("bildschirmloesung/", {
        params: { id: this.id },
      }).then(() => {
        this.schliesseLoeschenModal();
        this.$router.push({ name: "bildschirm-planer" });
      });
    },
    ladeBildschirme() {
      this.loading = true;

      var params = null;

      Api.get("/bildschirm/", {
        params: params,
      })
        .then((response) => {
          this.bildschirmListe = [];
          response.data.forEach((screen) => {
            this.bildschirmListe.push({
              ID: screen.ID,
              bezeichnung: screen.bezeichnung,
              style: "font-size: 0.8em; cursor: pointer",
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteMedium(medID) {
      Api.delete("medium/", {
        params: { id: medID, evId: this.id },
      }).then(() => {
        Api.get("bildschirmloesung/", { params: { id: this.id } }).then(
          (response) => {
            this.initializeBildschrimloesung(response.data);
          }
        );
      });
    },
  },
};
</script>

<style>
</style>
