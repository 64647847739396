<template>
  <div class="list">
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              headline="Anmeldung Warteliste"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filterPerson"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.person") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filterVeranstaltung"
                        :options="veranstaltungsliste"
                        :reduce="(vl) => vl.id"
                        label="titel"
                        placeholder="Veranstaltung"
                      >
                        <span slot="no-options"
                          >{{ $t("global.noeventound") }}</span
                        >
                      </v-select>
                      <label>{{ $t("global.event") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filterCount"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.registrationwaitinglist')"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4 mr-2">
                    <router-link
                      v-if="berechtigungen.m_anmeldungen.create"
                      :to="{
                        name: $t('globalnewregistration'),
                      }"
                      tag="button"
                      class="btn btn-success mr-2"
                      ><font-awesome-icon
                        icon="fa-solid fa-sign-out-alt"
                        class="mr-2"
                      />{{ $t("global.newregistration") }}</router-link
                    >
                    <button
                      class="btn btn-primary mr-2"
                      v-if="berechtigungen.m_anmeldungen.update"
                      @click="showAnmeldungUebertragen"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-sign-out-alt"
                        class="mr-2"
                      />
                      <span>{{ $t("global.transferregistration") }}</span>
                    </button>
                  </div>
                  <div class="mr-2">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_anmeldungen.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="openAnmeldung"
                  >
                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>

                    <template #cell(veranstaltung)="data">
                      {{ data.value.titel }}
                      <!--
                      <a :href="'/bildung/klasse/' + data.value.id">{{
                        data.value.titel
                      }}</a>-->
                    </template>

                    <template #cell(anmeldung)="data">
                      <a
                        :href="
                          '/stammdaten/geschaeftspartner/' +
                          data.value.person.id
                        "
                        >{{ data.value.person.personName }}</a
                      >
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">{{ $t("global.nofurtherdatafound") }}</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <anmeldung-uebertragen-modal
      id="anmeldung-uebertragen-modal"
      :anmeldung="selectedIds"
      :warteSchlange="true"
      @copied="anmeldungenKopiert"
      @changed="anmeldungenGeaendert"
    ></anmeldung-uebertragen-modal>

    <messagebox-enhanced :ok="true" :cancel="false" @ok="refreshList" />

    <messagebox-enhanced
      id="uebertragenWarnung"
      :ok="true"
      :cancel="true"
      @ok="showAnmeldungUebertragenGo"
    />

    <messagebox-count
      headerText="Anmeldung(en) gelöscht"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
            :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <loeschen-modal
      id="anmeldung-loeschen-modal"
      @confirmed="anmeldungLoeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { debounce } from "@/utils/Debouncer";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";

import InfiniteLoading from "vue-infinite-loading";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import MessageboxEnhanced from "@/components/Modals/MessageboxEnhanced.vue";
import AnmeldungUebertragenModal from "@/components/Modals/AnmeldungUebertragenModal";

import { SET_LISTEN_FILTER } from "@/store/dashboard/actions.type";

export default {
  name: "AnmeldungWarteliste",
  components: {
    Navbar,
    InfiniteLoading,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    MessageboxEnhanced,
    AnmeldungUebertragenModal,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.registrationswaitinglist");
      },
    };
  },
  mixins: [page, dashboard],
  props: {},
  store,
  data() {
    return {
      page: 0,
      anzahlProPage: 50,
      loading: false,
      listData: [],
      veranstaltungsliste: [],

      filterPerson: this.$route.query.filterPerson,
      filterVeranstaltung: this.$route.query.filterVeranstaltung,
      filterCount: null,

      infiniteId: +new Date(),
      fields: [
        { key: "selected", label: "" },
        { key: "veranstaltung", sortable: true, label: this.$t("global.event") },
        { key: "anmeldung", sortable: true, label: this.$t("global.person") },
        { key: "position", sortable: true, label: this.$t("global.registrationposition") },
        {
          key: "anmeldung.anmeldestatus.bezeichnung",
          sortable: true,
          label: this.$t("global.statusregistration"),
        },
        {
          key: "adresse",
          sortable: true,
          label: this.$t("global.residence"),
          formatter: (value) => {
            if (value) {
              return `${value.plz} ${value.ort}`;
            }
          },
        },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.filterPerson) params.filterPerson = this.filterPerson;
      if (this.filterVeranstaltung)
        params.filterVeranstaltung = this.filterVeranstaltung;

      if (this.filterCount != "") params.filterCount = this.filterCount;

      return params;
    },
  },
  watch: {
    filterPerson: function () {
      this.resetLoading(this);
    },
    filterVeranstaltung: function () {
      this.resetLoading(this);
    },
    filterCount: function () {
      this.resetLoading(this);
    },
  },
  created() {
    /*** Alle Veranstaltungen mit Einträgen in der Warteliste holen ***/
    Api.get("anmeldung/anmeldeliste_filter/?filter=veranstaltung", {
      params: { count: 15000 },
    }).then((response) => (this.veranstaltungsliste = response.data));
  },
  mounted() {
    let state = this.$store.state.dashboard.list["anmeldung-warte-liste"];

    if (state) {
      this.filterPerson = state.person;
      this.filterVeranstaltung = state.veranstaltung;
    }

    this.setHeaders("global", [
      "",
      "event",
      "person",
      "registrationposition",
      "statusregistration",
      "residence",
    ]);
  },
  methods: {
    refreshList() {
      this.resetLoading(this);
    },
    openAnmeldung(warteposition) {
      this.storeFilter();

      if (warteposition.anmeldung.id) {
        this.$router.push({
          name: "anmeldung",
          params: { anmeldungid: warteposition.anmeldung.id, anzeige: 0 },
        });
      }
    },

    storeFilter() {
      this.$store.dispatch(`dashboard/${SET_LISTEN_FILTER}`, {
        list: "anmeldung-warte-liste",
        state: {
          veranstaltung: this.filterPerson,
          person: this.filterVeranstaltung,
        },
      });
    },

    resetLoading: debounce((self) => {
      self.page = 0;
      self.anmeldeliste = [];
      self.infiniteId += 1;
    }, 500),

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "anmeldung-loeschen-modal");
    },

    anmeldungLoeschen() {
      this.delete("anmeldung/anmeldeliste/", this.selectedIds);
    },

    showAnmeldungUebertragen() {
      if (this.selectedIds.length == 0) return;

      this.$bus.$emit("openMessageBoxEnhanced", {
        id: "uebertragenWarnung",
        text: this.$t("notification.entrywaitinglistremoveddoyouwanttransferregistrations"),
        headerText: this.$t("global.transferregistration"),
      });
    },

    showAnmeldungUebertragenGo() {
      this.$bus.$emit("openAnmeldungUebertragenModal", this.selectedIds);
    },

    anmeldungenKopiert() {
      this.$bus.$emit("openMessageBoxEnhanced", {
        text: this.$t("notification.registrationssuccessfullycopied"),
        headerText: this.$t("notification.transferregistrations"),
      });
    },

    anmeldungenGeaendert() {
      this.$bus.$emit("openMessageBoxEnhanced", {
        text: this.$t("notification.registrationssuccessfullychanged"),
        headerText: this.$t("notification.transferregistrations"),
      });
    },

    infiniteHandler($state) {
      if (!this.loading) {
        this.loading = true;
        Api.get("anmeldung/anmeldeliste/", {
          params: this.queryParams,
        }).then((response) => {
          if (response.data.length === this.anzahlProPage) {
            this.page++;
            this.listData.push(...response.data);
            $state.loaded();
            this.loading = false;
          } else {
            console.log("do it");
            this.listData.push(...response.data);
            $state.complete();
            this.loading = false;
          }
        });
      }
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>

<style>
</style>
