<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="$t('global.appointment')"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success"
                    :disabled="invalid"
                    v-if="editable"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button
                    v-show="$CONST('CONFIG').APPTYPE == 'Education'"
                    class="btn btn-primary ml-2"
                    v-if="berechtigungen.b_bildung_anwesenheit.read"
                    @click="AnwesenheitBearbeiten"
                  >
                    {{ $t("global.showpresence") }}
                  </button>
                  <button class="btn btn-primary ml-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_ressourcen.delete"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-trash"
                      class="mr-2"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START Linke Seite -->

        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Stammdaten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div v-if="$CONST('CONFIG').APPTYPE == 'Education'" class="row">
                  <div class="col-xl-6">
                    <div class="form-checkbox">
                      <input
                        type="checkbox"
                        class="mr-2"
                        v-model="termin.infoscreen"
                      />
                      {{ $t("global.showoninfoscreen") }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <input
                          v-model.trim="termin.bezeichnung"
                          :readonly="!editable"
                          class="form-control"
                          v-bind:class="{ 'border-danger': !passed }"
                          placeholder=" "
                        />
                        <label v-bind:class="{ 'text-danger': !passed }">{{
                          $t("global.designation")
                        }}</label>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="termin.ressourcenstatus"
                        label="bezeichnung"
                        :options="ressourcenstati"
                        :reduce="(s) => s.id"
                        :disabled="!editable"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                          $t("notification.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-2">
                    <div class="form-group">
                      <ValidationProvider rules="required" immediate
                        ><input type="hidden" v-model.trim="termin.von"
                      /></ValidationProvider>
                      <date-picker
                        :inputClass="
                          termin.von == '' || termin.von == null
                            ? 'border-danger'
                            : ''
                        "
                        date-format="dd.mm.yy"
                        :initial="termin.von"
                        placeholer=" "
                        :show-icon="true"
                        :disabled="!editable"
                        @update="(val) => (termin.von = val)"
                      />
                      <label>{{ $t("global.from") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="termin.bis"
                        placeholder=" "
                        :show-icon="true"
                        :disabled="!editable"
                        @update="(val) => (termin.bis = val)"
                      />
                      <label>{{ $t("global.till") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-1 pl-0" :key="uhrzeitVonKey">
                    <ValidationProvider rules="required" immediate
                      ><input type="hidden" v-model.trim="termin.von_uhrzeit"
                    /></ValidationProvider>
                    <time-field
                      placeholder=" "
                      :label="$t('global.starttime')"
                      :readonly="!editable"
                      :initial="termin.von_uhrzeit"
                      :inputClass="
                        termin.von_uhrzeit == '' || termin.von_uhrzeit == null
                          ? 'border-danger'
                          : ''
                      "
                      @update="updateUhrzeitVon"
                    />
                  </div>

                  <div class="col-xl-1 pl-0" :key="uhrzeitBisKey">
                    <ValidationProvider rules="required" immediate
                      ><input type="hidden" v-model.trim="termin.bis_uhrzeit"
                    /></ValidationProvider>
                    <time-field
                      placeholder=" "
                      :label="$t('global.endtime')"
                      :readonly="!editable"
                      :initial="termin.bis_uhrzeit"
                      :inputClass="
                        termin.bis_uhrzeit == '' || termin.bis_uhrzeit == null
                          ? 'border-danger'
                          : ''
                      "
                      @update="updateUhrzeitBis"
                    />
                  </div>
                  <div
                    v-if="$CONST('CONFIG').APPTYPE == 'Education'"
                    class="col-xl-6"
                  >
                    <div class="form-group">
                      <v-select
                        v-model="termin.konfliktstatus"
                        label="bezeichnung"
                        :options="konfliktstati"
                        :reduce="(s) => s.id"
                        :disabled="!editable"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                          $t("notification.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.conflictstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE == 'Education'" class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model.number="termin.lektionen"
                        class="form-control"
                        :readonly="!editable || honorar"
                        placeholder=" "
                      />
                      <label>{{ $t("global.lessons") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <single-select-person
                      id="termin-verantwortlicher"
                      :person="termin.verantwortlich"
                      :label="$t('global.lecturer')"
                      :allowOpen="berechtigungen.m_geschaeftspartner.read"
                      :editable="editable"
                      @confirmed="setVerantwortlicher"
                    />
                  </div>
                </div>

                <div class="row">
                  <div
                    v-if="$CONST('CONFIG').APPTYPE == 'Education'"
                    class="col-xl-6"
                  >
                    <div class="form-group">
                      <v-select
                        v-model="termin.ressourcenbuchung"
                        label="bezeichnung"
                        :options="ressourcenbuchungstypen"
                        :reduce="(r) => r.id"
                        :disabled="!editable"
                        :placeholder="$t('global.externalinternal')"
                      >
                        <span slot="no-options">{{
                          $t("notification.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.resourcebooking") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <single-select-person
                      id="termin-reserviert-durch"
                      :person="termin.reserviert_durch"
                      :label="$t('global.reservedby')"
                      :allowOpen="berechtigungen.m_geschaeftspartner.read"
                      :editable="editable"
                      :required="true"
                      @confirmed="setReserviertDurch"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-model.trim="termin.bemerkung"
                        class="form-control"
                        :readonly="!editable"
                        placeholder=" "
                        rows="4"
                      ></textarea>
                      <label>{{ $t("global.comment") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Stammdaten -->
        </div>
        <!-- ENDE linke Seite -->
        <!-- START Rechte Seite -->
        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Reservationen -->
          <div>
            <container-headline
              :headline="$t('global.reservations')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div
                    v-show="$CONST('CONFIG').APPTYPE == 'Education'"
                    class="row"
                  >
                    <div class="col-xl-12">
                      <single-select-veranstaltung
                        id="termin-veranstaltung"
                        :veranstaltung="termin.veranstaltung"
                        :label="$t('global.event')"
                        :allowOpen="berechtigungen.m_bildung.read"
                        :editable="editable"
                        @confirmed="setVeranstaltung"
                      />
                    </div>
                  </div>
                  <div
                    v-show="$CONST('CONFIG').APPTYPE === 'DESKA'"
                    class="row"
                  >
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="selectedTyp"
                          label="typ"
                          :options="buchungstyp"
                          :reduce="(r) => r.typ"
                        >
                          <span slot="no-options">{{
                            $t("notification.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.bookingtype") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-3">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="termin.einrichtung_von"
                          placeholer=" "
                          :show-icon="true"
                          :disabled="!editable"
                          @update="(val) => (termin.einrichtung_von = val)"
                        />
                        <label>{{ $t("global.facilityfrom") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="termin.einrichtung_bis"
                          placeholder=" "
                          :show-icon="true"
                          :disabled="!editable"
                          @update="(val) => (termin.einrichtung_bis = val)"
                        />
                        <label>{{ $t("global.facilityuntil") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <time-field
                        placeholder=" "
                        :initial="termin.einrichtung_von_uhrzeit"
                        :readonly="!editable"
                        :label="$t('global.starttime')"
                        @update="
                          (val) => (termin.einrichtung_von_uhrzeit = val)
                        "
                      />
                    </div>

                    <div class="col-xl-3">
                      <div class="form-group">
                        <input
                          v-model.trim="termin.einrichtung_bis_uhrzeit"
                          class="form-control"
                          placeholder=" "
                          :readonly="!editable"
                          @update="
                            (val) => (termin.einrichtung_bis_uhrzeit = val)
                          "
                        />
                        <label>{{ $t("global.endtime") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <!-- v-select für Education -->
                        <v-select
                          v-model="termin.raumliste"
                          label="bezeichnung"
                          :options="raeume"
                          v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                          :reduce="(r) => r.id"
                          :placeholder="$t('global.room')"
                          :disabled="!editable"
                          multiple
                        >
                          <span slot="no-options">{{
                            $t("notification.taptosearch")
                          }}</span>
                        </v-select>
                        <!-- v-select für DESKA -->
                        <v-select
                          v-model="termin.raum"
                          label="bezeichnung"
                          :options="raeume"
                          v-if="$CONST('CONFIG').APPTYPE === 'DESKA'"
                          :reduce="(r) => r.id"
                          :placeholder="$t('global.room')"
                          :disabled="!editable"
                          @input="ladeArbeitsplaetze(termin.raum)"
                        >
                          <span slot="no-options">{{
                            $t("notification.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.room") }}</label>
                      </div>
                    </div>
                    <div
                      v-if="
                        selectedTyp == 'Arbeitsplatz' &&
                        termin.raum != null &&
                        $CONST('CONFIG').APPTYPE === 'DESKA'
                      "
                      class="col-xl-6"
                    >
                      <div class="form-group">
                        <v-select
                          v-model="termin.arbeitsplatz"
                          label="bezeichnung"
                          :options="arbeitsplatzListe"
                          :reduce="(r) => r.ID"
                          :placeholder="$t('global.workplace')"
                          :disabled="!editable"
                          multiple
                        >
                          <span slot="no-options">{{
                            $t("notification.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.workplace") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Reservationen -->
          <!-- START Finanzen -->
          <div v-show="$CONST('CONFIG').APPTYPE === 'Education'" v-if="id">
            <container-headline
              :headline="$t('dashboard.finance')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          v-model.trim="termin.rechnungsdatum"
                          class="form-control"
                          placeholder=" "
                          readonly="true"
                        />
                        <label>{{ $t("global.invoicedate") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Finanzen -->
        </div>
        <!-- ENDE Rechte Seite -->
        <div v-if="id" class="col-xl-12">
          <div v-if="$CONST('CONFIG').APPTYPE === 'Education'" class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item" v-if="berechtigungen.m_dozierende.read">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'termin',
                      params: { id: id, anzeige: 0 },
                    }"
                    >{{ $t("global.lecturerdeployment") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <!-- Start Dozenteneinsatz -->
                  <dozenteneinsatzliste
                    :termin="id"
                    :editable="editable"
                    :shown="anzeige == 0 ? true : false"
                  />
                  <!-- Ende Dozenteneinsatz -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <message-box
      id="termin-konflikt-modal"
      :headerText="$t('global.dateconflict')"
      :text="$t('global.aconflictwithanappointmentoccurredwhensaving')"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
    />

    <loeschen-modal id="termin-loeschen-modal" @confirmed="terminloeschen" />

    <change-watcher-alert />
  </div>
</template>

<script>
import Dozenteneinsatzliste from "@/components/Reiter/Dozenteneinsatzliste";
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";

import DatePicker from "@/components/Datepicker";
import TimeField from "@/components/TimeField.vue";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import LoeschenModal from "@/components/Modals/LoeschenModal.vue";
import MessageBox from "@/components/Modals/Messagebox.vue";

import { LADE_AUSWAHLWERTE_RESSOURCEN } from "@/store/ressourcen/actions.type";

import { textNotLinked } from "@/defaultTexts.js";

export default {
  name: "Termin",
  components: {
    Dozenteneinsatzliste,
    HeadMenu,
    Navbar,
    ContainerHeadline,
    DatePicker,
    SingleSelectPerson,
    LoeschenModal,
    SingleSelectVeranstaltung,
    MessageBox,
    TimeField,
  },
  metaInfo() {},
  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      uhrzeitBisKey: 1000,
      uhrzeitVonKey: 1,
      termin: {
        veranstaltung: { bezeichnung: textNotLinked },
        verantwortlich: null,
        reserviert_durch: null,
        raumliste: [],
        raum: null,
        arbeitsplatz: [],
        von: null,
        bis: null,
        von_uhrzeit: null,
        bis_uhrzeit: null,
      },
      arbeitsplatzListe: [],
      selectedTyp: "",
      buchungstyp: [
        {
          typ: "Raum",
        },
        {
          typ: "Arbeitsplatz",
        },
      ],
      dozenteneinsatz: {
        id: null,
      },
      honorar: null,
    };
  },
  computed: {
    ressourcenstati: {
      get() {
        return this.$store.state.ressourcen.ressourcenstati;
      },
    },
    ressourcenbuchungstypen: {
      get() {
        return this.$store.state.ressourcen.ressourcenbuchungstypen;
      },
    },
    raeume: {
      get() {
        return this.$store.state.ressourcen.raeume;
      },
    },
    konfliktstati: {
      get() {
        return this.$store.state.ressourcen.konfliktstati;
      },
    },
  },
  created() {
    if (this.id != null) {
      Api.get("termin/", { params: { id: this.id } }).then((response) => {
        this.initializeTermin(response.data);
      });
      Api.get("dozenteneinsatz/", { params: { termin: this.id } }).then(
        (response) => {
          this.initializeDozentenseinsatz(response.data);
          this.getHonorar();
        }
      );
    } else this.initializeTermin(this.termin);
  },
  mounted: function () {
    if (this.ressourcenstati.length == 0) {
      this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);
    }
  },
  methods: {
    updateUhrzeitVon(value) {
      this.termin.von_uhrzeit = value;
      this.uhrzeitVonKey++;
    },
    updateUhrzeitBis(value) {
      this.termin.bis_uhrzeit = value;
      this.uhrzeitBisKey++;
    },
    getHonorar() {
      Api.get("finanzen/honorar/", {
        params: { dozenteneinsatz: this.dozenteneinsatz[0].id },
      }).then((response) => {
        this.initializeHonorar(response.data);
      });
    },

    abbrechen() {
      this.$router.push({ name: "Terminliste" });
    },

    initializeDozentenseinsatz(dozenteneinsatz) {
      this.dozenteneinsatz = dozenteneinsatz;

      this.editable = this.berechtigungen.m_ressourcen.update;

      this.initChangeWatcher(this.termin);
    },

    initializeHonorar(honorar) {
      if (honorar?.length > 0) {
        this.honorar = honorar;
      }

      this.editable = this.berechtigungen.m_ressourcen.update;

      this.initChangeWatcher(this.termin);
    },

    initializeTermin(termin) {
      this.termin = termin;

      if (this.termin.reserviert_durch == null)
        this.termin.reserviert_durch = this.angemeldeterMitarbeiter.person;

      /*f (this.termin.verantwortlich == null) {
        this.termin.verantwortlich = this.angemeldeterMitarbeiter.person;
      }*/

      this.editable = this.berechtigungen.m_ressourcen.update;

      this.initChangeWatcher(this.termin);
      this.ladeArbeitsplaetze();
    },

    ladeArbeitsplaetze(id) {
      this.loading = true;

      //var params = null;

      Api.get("/arbeitsplatz/", {
        params: { raumID: id },
      })
        .then((response) => {
          this.arbeitsplatzListe = [];
          if (response.data) {
            response.data.forEach((arbeitsplatz) => {
              this.arbeitsplatzListe.push({
                ID: arbeitsplatz.id,
                bezeichnung: arbeitsplatz.bezeichnung,
                style: "font-size: 0.8em; cursor: pointer",
              });
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setVerantwortlicher(person) {
      this.termin.verantwortlich = person;
    },

    setReserviertDurch(person) {
      this.termin.reserviert_durch = person;
    },

    setVeranstaltung(veranstaltung) {
      this.termin.veranstaltung = veranstaltung;
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;

      if (this.termin.bis == null || this.termin.bis == "")
        this.termin.bis = this.termin.von;

      var json = Object.assign({}, this.termin);

      /*json.veranstaltung = this.termin.veranstaltung.id;
      json.verantwortlich = this.termin.verantwortlich.id;
      json.reserviert_durch = this.termin.reserviert_durch.id;*/

      /*if (
        this.termin.ressourcenbuchung != null &&
        this.termin.ressourcenbuchung.id
      )
        json.ressourcenbuchung = this.termin.ressourcenbuchung.id;
      else json.ressourcenbuchung = this.termin.ressourcenbuchung;

      if (
        this.termin.ressourcenstatus != null &&
        this.termin.ressourcenstatus.id
      )
        json.ressourcenstatus = this.termin.ressourcenstatus.id;
      else json.ressourcenstatus = this.termin.ressourcenstatus;

      if (this.termin.konfliktstatus != null && this.termin.konfliktstatus.id)
        json.konfliktstatus = this.termin.konfliktstatus.id;
      else json.konfliktstatus = this.termin.konfliktstatus;

      json.raumliste = this.termin.raumliste.map((raum) =>
        raum.id ? raum.id : raum
      );
*/
      if (!this.id) {
        Api.post("termin/", json)
          .then((response) => {
            this.postSave(response.data);
             this.$router.replace({
              name: "termin",
              params: { id: response.data.termin.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("termin/", json, { params: { id: this.termin.id } })
          .then((response) => {
            this.postSave(response.data);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
            
          });
      }
    },

    postSave(data) {
      this.initializeTermin(data.termin);

      if (data.konflikte[0] != null)
        this.$bus.$emit("open-modal", "termin-konflikt-modal");

      this.$notify({
        type: "success",
        title: this.$t("notification.actionsuccessful"),
        text: this.$t("notification.appointmentsuccessfullysaved"),
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "termin-loeschen-modal");
    },

    terminloeschen() {
      Api.delete("termin/", {
        params: { id: this.id },
      }).then(() => {
        this.$router.push({ name: "Terminliste" });
      });
    },

    AnwesenheitBearbeiten() {
      this.$router.push({
        path:
          "/bildung/anwesenheit/" +
          this.termin.veranstaltung.id +
          "/" +
          this.id,
      });
    },
  },
};
</script>

<style>
</style>
