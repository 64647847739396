<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-veranstaltung
                      id="termin-veranstaltung"
                      :label="$t('global.event')"
                      :veranstaltung="filters.veranstaltung"
                      :allowOpen="false"
                      editable
                      @confirmed="setVeranstaltung"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.raum"
                        :options="raeume"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.room')"
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.room") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="termin-dozent"
                      :person="filters.dozent"
                      :label="$t('global.Lecturer')"
                      :allowOpen="false"
                      :rollen="filterRollen"
                      :editable="true"
                      :tabindex="3"
                      @confirmed="setFilterDozent"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.calendar')"
            ></head-menu>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div style="height: 80vh">
                  <calendar-view
                    :show-date="showDate"
                    :items="items"
                    showTimes
                    :timeFormatOptions="{
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    }"
                    class="
                      theme-default
                      holiday-us-traditional holiday-us-official
                    "
                    @click-item="terminOeffnen"
                  >
                    <calendar-view-header
                      slot="header"
                      slot-scope="t"
                      :header-props="t.headerProps"
                      @input="setShowDate"
                    />
                  </calendar-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";

import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";

import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
// The next two lines are processed by webpack. If you're using the component without webpack compilation,
// you should just create <link> elements for these. Both are optional, you can create your own theme if you prefer.
require("vue-simple-calendar/static/css/default.css");
require("vue-simple-calendar/static/css/holidays-us.css");

import { parseDateTimeString } from "@/utils/bxchTools.js";

import { LADE_AUSWAHLWERTE_RESSOURCEN } from "@/store/ressourcen/actions.type";

export default {
  name: "TerminKalender",
  components: {
    HeadMenu,
    HeadMenuFilter,
    Navbar,
    CalendarView,
    CalendarViewHeader,
    SingleSelectPerson,
    SingleSelectVeranstaltung,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.calendar");
      },
    };
  },
  mixins: [page],
  store,
  props: {},
  data() {
    return {
      items: [],
      showDate: new Date(),
      filterRollen: this.$CONST("ROLLEN").DOZENTEN,
      filters: {
        raum: null,
      },
    };
  },
  watch: {
    filters: {
      handler: function () {
        this.ladeTermine(this.showDate);
      },
      deep: true,
    },
    showDate: {
      handler: function (val) {
        this.ladeTermine(val);
      },
    },
  },
  computed: {
    raeume: {
      get() {
        return this.$store.state.ressourcen.raeume;
      },
    },
  },
  created() {
    if (this.raeume.length == 0)
      this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);
  },
  mounted: function () {
    this.ladeTermine(this.showDate);
  },
  methods: {
    setQueryParam(value) {
      return value && value != "" ? value : null;
    },

    setShowDate(d) {
      this.showDate = d;
    },

    setFilterDozent(dozent) {
      this.$set(this.filters, "dozent", dozent);
    },

    setVeranstaltung(veranstaltung) {
      this.filters.veranstaltung = veranstaltung;
    },

    terminOeffnen(calendarItem) {
      this.$router.push({
        name: "termin",
        params: { id: calendarItem.id, anzeige: 0 },
      });
    },

    ladeTermine(datum) {
      this.loading = true;

      var params = {
        raum: this.setQueryParam(this.filters.raum),
        dozentFilter: this.setQueryParam(this.filters.dozent?.id),
      };

      var lastDay = new Date(datum.getFullYear(), datum.getMonth() + 1, 0);

      params.vonFilter =
        "1." + (datum.getMonth() + 1) + "." + datum.getFullYear();
      params.bisFilter =
        lastDay.getDate() +
        "." +
        (datum.getMonth() + 1) +
        "." +
        datum.getFullYear();

      Api.get("/termin/", {
        params: params,
      })
        .then((response) => {
          this.items = [];
          response.data.forEach((termin) => {
            console.log(termin);
            this.items.push({
              id: termin.id,
              startDate: parseDateTimeString(termin.von, termin.von_uhrzeit),
              endDate: parseDateTimeString(termin.bis, termin.bis_uhrzeit),
              title: termin.veranstaltung
                ? termin.veranstaltung.title
                : termin.bezeichnung,
              style: "font-size: 0.8em; cursor: pointer",
            });
          });
          console.log(this.items);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

