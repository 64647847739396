<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.firma"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.company") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.ansprechpartner"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.Contactperson") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="verantwortlicher"
                      :person="filters.verantwortlicher"
                      :label="$t('global.Responsible')"
                      :allowOpen="false"
                      editable
                      :rollen="filterMitarbeitende"
                      @confirmed="setVerantwortlicher"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.typ"
                        label="bezeichnung"
                        :options="leadtypen"
                        :placeholder="$t('dashboard.type')"
                      >
                        <span slot="no-options">{{
                          $t("notification.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("dashboard.type") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.stage"
                        label="bezeichnung"
                        :options="leadstages"
                        :placeholder="$t('global.stage')"
                      >
                        <span slot="no-options">{{
                          $t("notification.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.stage") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.artikel"
                        :options="artikelListe"
                        label="bezeichnung"
                        :placeholder="$t('global.item')"
                      >
                        <template slot="option" slot-scope="option">
                          {{ option.kurzbezeichnung }} -
                          {{ option.bezeichnung }} ({{ option.artikelnummer }})
                        </template>

                        <template slot="selected-option" slot-scope="option">
                          {{ option.kurzbezeichnung }} -
                          {{ option.bezeichnung }} ({{ option.artikelnummer }})
                        </template>
                        <span slot="no-options">{{
                          $t("notification.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.item") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('dashboard.leads')"
              :date="'Mittwoch 29.Juli'"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>

            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4 mr-2">
                    <button
                      class="btn btn-success mr-2"
                      @click="neuerLead"
                      v-if="berechtigungen.m_lead.create"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </button>
                  </div>
                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_lead.delete"
                      :disabled="isDisabled"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />
                      <span>{{ $t("global.delete") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste belegliste-font12">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(wahrscheinlichkeit)="row">
                      <div
                        v-if="row.item.stage"
                        :style="
                          row.item.stage.wahrscheinlichkeit === 0
                            ? 'background-color:#ff9681; color:white'
                            : row.item.stage.wahrscheinlichkeit === 100
                            ? 'background-color:#adddcf; color:white'
                            : ''
                        "
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template #cell(show_details)="row">
                      <b-button
                        size="sm"
                        @click="row.toggleDetails"
                        class="mr-2"
                      >
                        <font-awesome-icon
                          v-if="row.detailsShowing"
                          icon="fa-regular fa-minus"
                        />
                        <font-awesome-icon
                          v-if="!row.detailsShowing"
                          icon="fa-regular fa-plus"
                        />
                      </b-button>
                    </template>

                    <template #row-details="row">
                      <b-card>
                        <b-row class="mb-2">
                          <b-col sm="2" class="text-sm-right">
                            <b>{{ $t("global.kurznotiz") }}</b
                            >:
                          </b-col>
                          <b-col>{{ row.item.kurznotiz }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                          <b-col sm="2" class="text-sm-right">
                            <!--<a
                              :href="
                                '/kommunikation/mail/' +
                                row.item.ansprechpartner.id +
                                '/0'
                              "
                              target="_new"
                            >
                              <font-awesome-icon
                                icon="fa-regular fa-envelope"
                                style="padding-right: 5px" /></a
                            >--><b>{{ $t("global.email") }}</b
                            >:
                          </b-col>
                          <b-col
                            ><a
                              :href="
                                'mailto:' + getEMail(row.item.ansprechpartner)
                              "
                              >{{ getEMail(row.item.ansprechpartner) }}</a
                            ></b-col
                          >
                        </b-row>
                        <b-row class="mb-2">
                          <b-col sm="2" class="text-sm-right"
                            ><b>{{ $t("global.stage") }}</b
                            >:
                          </b-col>
                          <b-col sm="4">{{ row.item.stage.bezeichnung }}</b-col>
                          <b-col sm="2" class="text-sm-right"
                            ><b>{{ $t("global.FirstContact") }}</b
                            >:
                          </b-col>
                          <b-col sm="4">{{
                            row.item.erstkontakt
                              ? row.item.erstkontakt.personName
                              : ""
                          }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                          <b-col sm="2" class="text-sm-right"
                            ><b>{{ $t("global.completiondate") }}</b
                            >:
                          </b-col>
                          <b-col sm="4">{{ row.item.abschlussdatum }}</b-col>
                          <b-col sm="2" class="text-sm-right"
                            ><b>{{ $t("global.Responsible") }}</b
                            >:
                          </b-col>
                          <b-col sm="4">{{
                            row.item.verantwortlicher
                              ? row.item.verantwortlicher.personName
                              : ""
                          }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                          <b-col sm="2" class="text-sm-right"
                            ><b>{{ $t("global.recorded") }}</b
                            >:
                          </b-col>
                          <b-col sm="2">{{ row.item.erfassungsdatum }}</b-col>
                        </b-row>
                      </b-card>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loeschen-modal
      id="leads-loeschen-modal"
      @confirmed="loeschen"
      :multiple="true"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import store from "@/store";
import page from "@/mixins/Page";
import numeral from "numeral";
import dashboard from "@/mixins/Dashboard";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

import { BRow, BCol } from "bootstrap-vue";

import { LADE_AUSWAHLWERTE_CRM } from "@/store/crm/actions.type";
import { LADE_ARTIKEL } from "@/store/artikel/actions.type";

export default {
  name: "Leads",
  components: {
    BRow,
    BCol,
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    SingleSelectPerson,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("dashboard.leads");
      },
    };
  },
  mixins: [page, dashboard],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      isDisabled: false,
      filterMitarbeitende: this.$CONST("ROLLEN").MITARBEITER,

      fields: [
        { key: "selected", label: "" },
        {
          key: "wahrscheinlichkeit",
          sortable: true,
          label: this.$t("global.stage"),
          searchable: false,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: (value, key, item) => {
            if (!item.stage) return "";
            else if (item.stage.wahrscheinlichkeit)
              return numeral(item.stage.wahrscheinlichkeit / 100).format("%");
            else return item.stage.wahrscheinlichkeit == 0 ? "0%" : "";
          },
        },
        {
          key: "typ.appkey",
          label: this.$t("dashboard.type"),
          sortable: true,
          formatter: (value) => {
            if (value) {
              return value == "leada" ? "A" : value == "leadb" ? "B" : "C";
            } else {
              return "";
            }
          },
          tdClass: "text-center",
          thClass: "text-center",
          searchable: false,
        },
        {
          key: "umsatzbewertet",
          sortable: true,
          label: this.$t("global.sales"),
          formatter: (value) => {
            return value ? numeral(value / 1000).format("#,##0") + "K" : "";
          },
          tdClass: "text-center",
          thClass: "text-center",
          searchable: false,
        },

        {
          key: "firma",
          sortable: true,
          label: this.$t("global.company"),
          searchable: true,
          formatter: (value) => {
            if (value) return value.name;
            else return "";
          },
        },
        {
          key: "ansprechpartner",
          sortable: true,
          label: this.$t("global.Contactperson"),
          searchable: true,
          formatter: (value) => {
            if (value) return value.personName;
            else return "";
          },
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
          searchable: false,
        },

        {
          key: "telefon",
          label: this.$t("global.phone"),
          searchable: false,
          formatter: (value, key, item) => {
            let phone = "";
            let scope = item.ansprechpartner;

            if (scope) {
              if (scope.telefon_m && scope.telefon_m != "") {
                phone = scope.telefon_m;
              } else if (scope.telefon_g && scope.telefon_g != "") {
                phone = scope.telefon_g;
              } else {
                phone = scope.telefon_p;
              }
            }

            return phone ? phone : "";
          },
        },

        {
          key: "noofcalls",
          label: this.$t("global.NoOfCalls"),
          searchable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "naechsterkontakt",
          label: this.$t("global.NextContact"),
          searchable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },

        {
          key: "show_details",
          label: "",
          searchable: false,
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      params.typ = this.setQueryParam(this.filters.typ?.id);
      params.stage = this.setQueryParam(this.filters.stage?.id);
      params.art = this.setQueryParam(this.filters.artikel?.id);
      params.f = this.setQueryParam(this.filters.firma);
      params.ap = this.setQueryParam(this.filters.ansprechpartner);
      params.ver = this.setQueryParam(this.filters.verantwortlicher?.id);

      if (this.filters.count != "") params.filterCount = this.filters.count;

      return params;
    },
    artikelListe() {
      let tempList = this.$store.state.artikel.artikel;
      return tempList.sort((a, b) =>
        a.kurzbezeichnung.localeCompare(b.kurzbezeichnung)
      );
    },
    leadstages: {
      get() {
        return this.$store.state.crm.leadstages;
      },
    },
    leadtypen: {
      get() {
        return this.$store.state.crm.leadtypen;
      },
    },
  },
  created() {
    this.initFilter("lead-liste", "crm/lead/", true);
  },
  mounted() {
    if (this.leadtypen.length == 0)
      this.$store.dispatch(`crm/${LADE_AUSWAHLWERTE_CRM}`);

    if (this.artikelListe.length == 0)
      this.$store.dispatch(`artikel/${LADE_ARTIKEL}`);
    this.setHeaders("global", [
      "",
      "stage",
      "",
      "sales",
      "company",
      "Contactperson",
      "designation",
      "phone",
      "NoOfCalls",
      "NextContact",
    ]);
    this.setHeaders("dashboard", ["", "", "type"]);
  },
  methods: {
    details(lead) {
      this.$router.push({
        name: "lead",
        params: { id: lead.id, anzeige: 0 },
      });
    },

    getEMail(person) {
      let email = "";

      if (person && person.mailadressen && person.mailadressen[0]) {
        let mailadressen = person.mailadressen;
        email = mailadressen[0].bezeichnung;

        for (var idx = 1; idx < mailadressen.length; idx++) {
          if (mailadressen[idx].hauptadresse)
            email = mailadressen[idx].bezeichnung;
        }
      }

      return email;
    },

    neuerLead() {
      this.$router.push({
        name: "lead",
        params: { anzeige: 0 },
      });
    },

    loeschen() {
      Api.delete("crm/lead/", { data: this.selectedIds }).then((response) => {
        this.schliesseLoeschenModal();

        this.listData = this.listData.filter(
          (v) => !response.data.success.includes(v.id)
        );
      });
    },

    schliesseLoeschenModal() {
      $("#leads-loeschen-modal").modal("hide");
    },

    oeffneLoeschenModal() {
      $("#leads-loeschen-modal").modal("show");
    },

    setVerantwortlicher(person) {
      this.$set(this.filters, "verantwortlicher", person);
    },
  },
};
</script>

<style>
</style>
