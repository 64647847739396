<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filterKurzbezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filterBezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filterLehrgangstatus"
                        :options="lehrgangstati"
                        :reduce="(lsl) => lsl.id"
                        label="bezeichnung"
                        :placeholder="$t('global.statuseducationalcourse')"
                      >
                        <span slot="no-options"
                          >{{ $t("global.nostatuseducationcoursefound") }}</span
                        >
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="termin-dozent"
                      :person="filterDozent"
                      :label="$t('global.responsible')"
                      :allowOpen="false"
                      :rollen="filterRollen"
                      :editable="true"
                      @confirmed="setFilterDozent"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filterCount"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.templatesofeducationalcourses')"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row">
                  <div class="ml-4 mr-2">
                    <router-link
                      :to="{
                        name: 'template-bildungsgang',
                        params: { anzeige: 0 },
                      }"
                      tag="button"
                      class="btn btn-success"
                      ><font-awesome-icon icon="fa-regular fa-plus" /> {{ $t("global.newtemplate") }}</router-link
                    >
                  </div>
                  <div class="mr-2">
                    <button class="btn btn-danger" @click="oeffneLoeschenModal">
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="veranstaltungen"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template v-slot:head(selected)="row">
                      <b-form-checkbox
                        v-model="row.selected"
                        @input="selectAllRows(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">{{ $t("global.nofurtherdatafound") }}</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="bildungsgang-loeschen-modal"
      @confirmed="bildungsgangloeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { debounce } from "@/utils/Debouncer";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import InfiniteLoading from "vue-infinite-loading";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import { textNotLinked } from "@/defaultTexts.js";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";
import { SET_LISTEN_FILTER } from "@/store/dashboard/actions.type";

export default {
  name: "template-bildungsgang-liste",
  components: {
    Navbar,
    InfiniteLoading,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    SingleSelectPerson,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.templateseducationalcourses");
      },
    };
  },
  mixins: [page],
  props: {},
  store,
  data() {
    return {
      page: 0,
      veranstaltungen: [],
      anzahlProPage: 50,

      filterBezeichnung: this.$route.query.filterBezeichnung,
      filterKurzbezeichnung: this.$route.query.filterKurzbezeichnung,
      filterLehrgangstatus: this.$CONST("LEHRGANGSTATI").PLANNED,
      filterCount: null,
      verantwortlicher: this.$route.query.verantwortlicher,
      filterDozent: { personName: textNotLinked },
      filterRollen: this.$CONST("ROLLEN").DOZENTEN,

      infiniteId: +new Date(),
      sortBy: "bezeichnung",
      sortDesc: false,

      fields: [
        { key: "selected", label: "" },
        { key: "kuerzel", sortable: true, label: this.$t("global.shortdesignation") },
        { key: "bezeichnung", sortable: true, label: this.$t("global.designation") },
        {
          key: "lehrgangleiter.personName",
          sortable: true,
          label: this.$t("global.responsible"),
        },
        {
          key: "lehrgangstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
      ],

      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      params.filterBezeichnung = this.filterBezeichnung;
      params.filterKurzbezeichnung = this.filterKurzbezeichnung;
      params.filterLehrgangstatus = this.filterLehrgangstatus;
      params.verantwortlicher = this.verantwortlicher;

      if (this.filterDozent.id) params.dozent = this.filterDozent.id;

      if (this.filterCount != "") params.filterCount = this.filterCount;

      return params;
    },
    lehrgangstati: {
      get() {
        return this.$store.state.veranstaltung.lehrgangstati;
      },
    },
  },
  watch: {
    filterBezeichnung: function () {
      this.resetLoading(this);
    },
    filterKurzbezeichnung: function () {
      this.resetLoading(this);
    },
    filterLehrgangstatus: function () {
      this.resetLoading(this);
    },
    verantwortlicher: function () {
      this.resetLoading(this);
    },
    filterDozent: function () {
      this.resetLoading(this);
    },
    filterCount: function () {
      this.resetLoading(this);
    },
  },
  created() {},
  mounted() {
    if (this.lehrgangstati.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);

    let state = this.$store.state.dashboard.list["template-bildungs-liste"];

    if (state) {
      this.filterBezeichnung = state.bezeichnung;
      this.filterKurzbezeichnung = state.kurzbezeichnung;
      this.filterLehrgangstatus = state.lehrgangstatus
        ? state.lehrgangstatus
        : this.$CONST("LEHRGANGSTATI").PLANNED;
      this.verantwortlicher = state.verantwortlicher;
    } else {
      this.filterLehrgangstatus = this.$CONST("LEHRGANGSTATI").PLANNED;
    }
  },
  methods: {
    details(bildungsgang) {
      this.storeFilter();

      this.$router.push({
        name: "template-bildungsgang",
        params: { lehrgangid: bildungsgang.id, anzeige: 0 },
      });
    },

    setFilterDozent(person) {
      this.filterDozent = person;
    },

    resetLoading: debounce((self) => {
      self.page = 0;
      self.veranstaltungen = [];
      self.infiniteId += 1;
    }, 500),

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal", "bildungsgang-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: "Kein Bildungsgang ausgewählt.",
        });
      }
    },

    bildungsgangloeschen() {
      Api.delete("template/lehrgang/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
      this.$notify({
        type: "success",
        title: this.$t("notification.actionsuccessful"),
        text: "Vorlage Bildungsgang gelöscht",
      });
    },

    storeFilter() {
      this.$store.dispatch(`dashboard/${SET_LISTEN_FILTER}`, {
        list: "template-bildungs-liste",
        state: {
          bezeichnung: this.filterBezeichnung,
          kurzbezeichnung: this.filterKurzbezeichnung,
          lehrgangstatus: this.filterLehrgangstatus,
          verantwortlicher: this.verantwortlicher,
        },
      });
    },

    infiniteHandler($state) {
      let state1 = false;
      let state2 = false;

      Api.get("/template/lehrgang/", {
        params: this.queryParams,
      }).then((response) => {
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.veranstaltungen.push(...response.data);
          $state.loaded();
        } else {
          this.veranstaltungen.push(...response.data);
          state2 = true;
          if (state1 && state2) {
            $state.complete();
          }
        }
      });
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>

<style>
</style>
