<template>
  <div :id="id" class="modal fade" style="z-index: 12000">
    <div class="modal-dialog modal-xl">
      <div class="modal-content" style="border: 1px solid black">
        <div class="modal-header">
          <modal-close-button @confirmed="abbrechen" />
          <h4 class="modal-title">{{ $t("global.searchcompany") }}</h4>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  ref="editFirma"
                  v-model.trim="firma"
                  class="form-control"
                  placeholder=" "
                  @keyup.enter="resetLoading()"
                />
                <label>{{ $t("global.company") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  v-model.trim="filterStrasseNr"
                  class="form-control input"
                  type="text"
                  placeholder=" "
                  @keyup.enter="resetLoading()"
                />
                <label>{{ $t("global.street") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  v-model.trim="filterPlz"
                  class="form-control input"
                  type="text"
                  placeholder=" "
                  @keyup.enter="resetLoading()"
                />
                <label>{{ $t("global.zipcode") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  v-model.trim="filterOrt"
                  class="form-control input"
                  type="text"
                  placeholder=" "
                  @keyup.enter="resetLoading()"
                />
                <label>{{ $t("global.city") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 block modal-liste br-t-l-0">
              <div style="height: 300px !important">
                <b-table
                  responsive
                  sticky-header
                  selectable
                  select-mode="single"
                  @row-dblclicked="rowDblClick"
                  @row-selected="onRowSelected"
                  tbody-tr-class="item"
                  :items="geschaeftspartner"
                  :fields="fields"
                  :busy="isBusy"
                  :show-empty="loaded"
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center text-primary my-2">
                      {{ $t("global.nodatafound") }}
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <router-link
            v-if="berechtigungen.m_geschaeftspartner.create"
            class="btn btn-success mr-auto"
            :to="{
              name: 'firma',
              params: { anzeige: 0 },
            }"
            target="_blank"
          >
            <font-awesome-icon icon="fa-regular fa-plus" />
            {{ $t("global.newcompany") }}
          </router-link>

          <button type="button" class="btn btn-default" @click="abbrechen">
            {{ $t("global.cancel") }}
          </button>
          <button
            type="button"
            class="btn btn-danger mr-2"
            @click="entfernen"
            v-if="filterFirma && filterFirma.name && remove"
          >
            {{ $t("global.remove") }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="uebernehmen"
            :disabled="selected.length == 0"
          >
            {{ $t("global.apply") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import ModalCloseButton from "@/components/global/ModalCloseButton";
import { BSpinner } from "bootstrap-vue";

import { textNotLinked } from "@/defaultTexts.js";

export default {
  name: "firmaSucheModal",
  components: { ModalCloseButton, BSpinner },
  props: {
    id: {
      type: String,
      required: false,
      default: "geschaeftspartner-modal",
    },
    rechnungstraeger: {
      type: String,
      required: false,
    },
    adressTypen: {
      type: Array,
      required: false,
    },
    filterRollen: {
      type: Array,
      required: false,
    },
    filterFirma: {
      type: Object,
      required: false,
    },
    filterPerson: {
      type: Object,
      required: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    activeonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isBusy: false,
      loaded: false,
      anzahlProPage: 99,
      geschaeftspartner: [],
      filterStrasseNr: null,
      filterPlz: null,
      filterOrt: null,
      fields: [
        { key: "name", sortable: true, label: this.$t("global.company") },
        {
          key: "strasse_nr",
          sortable: true,
          label: this.$t("global.street"),
          formatter: (value, key, item) => {
            if (item && item.adressen && item.adressen.length > 0)
              return item.adressen[0].strasse_nr;
            else return "";
          },
        },
        {
          key: "adressen[0].ort",
          sortable: true,
          label: this.$t("global.city"),
        },
        {
          key: "adressen[0].plz",
          sortable: true,
          label: this.$t("global.zip"),
        },
        {
          key: "adressen[0].ort",
          sortable: true,
          label: this.$t("global.city"),
        },
        {
          key: "aktivitaetsstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
      ],
      firma: null,
      zip: null,
      selected: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.activeonly) params.status = "1737FA777AA";

      params.adresstyp = this.adressTypen ? this.adressTypen.join() : null;
      params.rollen = this.filterRollen ? this.filterRollen.join() : null;
      params.firma = this.firma;
      params.person = this.filterPerson?.id;
      params.suche = this.vorname;
      params.zip = this.zip;
      params.rechnungstraeger = this.rechnungstraeger;

      params.typ = "17EF88469AE";
      if (this.filterStrasseNr) params.strasse_nr = this.filterStrasseNr;
      if (this.filterPlz) params.zip = this.filterPlz;
      if (this.filterOrt) params.ort = this.filterOrt;
      //params.incf = "1";

      return params;
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("openFirmaModal", (id) => {
      if (id == this.id && !this.shown) this.openModal();
    });
  },
  methods: {
    openModal() {
      this.firma = null;
      this.zip = null;
      this.geschaeftspartner = [];

      this.shown = true;

      let that = this;

      if (this.filterFirma?.id) {
        this.firma = this.filterFirma?.name;

        this.resetLoading();
      }
      if (this.filterPerson?.id) {
        this.resetLoading();
      }
      if (this.rechnungstraeger) {
        this.resetLoading();
      }
      $("#" + this.id).on("shown.bs.modal", function () {
        if (that.$refs && that.$refs.editName) that.$refs.editName.focus();
      });

      $("#" + this.id).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    onRowSelected(items) {
      this.selected = items;
    },

    rowDblClick(item) {
      this.selected = [item];
      this.uebernehmen();
    },

    resetLoading() {
      this.isBusy = true;

      Api.get("geschaeftspartner/firma/", {
        params: this.queryParams,
      })
        .then((response) => {
          //response.data.shift();
          this.geschaeftspartner = response.data;
        })
        .finally(() => {
          this.isBusy = false;
          this.loaded = true;
        });
    },

    entfernen() {
      this.closeModal();
      this.$emit("confirmed", { name: textNotLinked });
    },

    uebernehmen() {
      if (this.selected == null || this.selected.length == 0) return;
      this.closeModal();
      this.$emit("confirmed", this.selected);
    },

    abbrechen() {
      this.closeModal();
    },
  },
};
</script>

<style>
</style>
