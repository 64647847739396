<template>
  <div class="list">
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betreff"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabindex="1"
                      />
                      <label>{{ $t("global.subject") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.inhalt"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabindex="2"
                      />
                      <label>{{ $t("global.content") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['absender.personName']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabindex="3"
                      />
                      <label>{{ $t("global.sender") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.empfaengermail"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabindex="4"
                      />
                      <label>{{ $t("global.receiver") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="filters.von"
                        placeholder=" "
                        :show-icon="true"
                        field="von"
                        @updatefull="setFilterDate"
                        :tabindex="5"
                      />
                      <label>{{ $t("global.from") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="filters.bis"
                        placeholder=" "
                        :show-icon="true"
                        field="bis"
                        @updatefull="setFilterDate"
                        :tabindex="6"
                      />
                      <label>{{ $t("global.till") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12 my-2">
                    <div class="form-group-inline" style="margin-left: 7px">
                      <label
                        class="float-left"
                        style="
                          font-size: 11px !important;
                          font-weight: 700 !important;
                        "
                        >{{ $t("global.onlytestmailsent") }}</label
                      ><input
                        class="form-check-input float-right mx-3"
                        style="margin-top: 0.2rem"
                        v-model="filters['testmail']"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabindex="7"
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.mail')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <router-link
                      v-if="berechtigungen.m_korrespondenz.create"
                      class="btn btn-success"
                      :to="{ name: 'mail', params: { anzeige: 0 } }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_korrespondenz.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDatumGesendet"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">{{ $t("global.nofurtherdatafound") }}</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="mail-loeschen-modal"
      :selectedIds="this.selectedIds"
      :multiple="true"
      @confirmed="mailLoeschen"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import DatePicker from "@/components/Datepicker";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";

export default {
  name: "Mailliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    DatePicker,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.Mails");
      },
    };
  },
  mixins: [page, dashboard],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      fields: [
        { key: "selected", label: "" },
        { key: "betreff", sortable: true, label: this.$t("global.subject") },
        { key: "absender.personName", sortable: true, label: this.$t("global.sender") },
        {
          key: "empfaengermail",
          sortable: true,
          label: this.$t("global.receiver"),
          formatter: (value) => {
            if (value) return value;
            else return this.$t("global.massmail");
          },
        },
        { key: "gesendet", sortable: true, label: this.$t("global.date"), searchable: false },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      params.filterBetreff = this.setQueryParam(this.filters.betreff);
      params.filterAbsender = this.setQueryParam(
        this.filters["absender.personName"]
      );
      params.filterEmpfaenger = this.setQueryParam(this.filters.empfaengermail);
      //params.filterDatum = this.setQueryParam(this.filters.zeitpunkt);
      params.filterVon = this.setQueryParam(this.filters.von);
      params.filterBis = this.setQueryParam(this.filters.bis);
      params.filterInhalt = this.setQueryParam(this.filters.inhalt);
      params.filterCount = this.setQueryParam(this.filterCount);
      params.filterTestmail = this.setQueryParam(this.filters["testmail"]);

      return params;
    },
  },
  watch: {},
  created() {
    this.initFilter("mail-liste", "mail/", true);
  },
  mounted() {
    this.setHeaders("global", [
      "",
      "subject",
      "sender",
      "receiver",
      "date",
    ]);
  },
  methods: {
    details(mailliste) {
      this.$router.push({
        name: "mail",
        params: { id: mailliste.id, anzeige: 0 },
      });
    },

    mailLoeschen() {
      Api.delete("mail", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "mail-loeschen-modal");
    },
    sortDatumGesendet(datumA, datumB, key) {
      if (key != "gesendet") {
        return false;
      } else {
        let a = null;
        let b = null;

        if (key.includes(".")) {
          a = this.resolveTermin(key, datumA);
          b = this.resolveTermin(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
            parseInt(a[2], 10) * 10000 +
            parseInt(a[1], 10) * 100 +
            parseInt(a[0]);
          b =
            parseInt(b[2], 10) * 10000 +
            parseInt(b[1], 10) * 100 +
            parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    },
    resolveTermin(path, obj = self, separator = ".") {
      var properties = Array.isArray(path) ? path : path.split(separator);
      return properties.reduce((prev, curr) => prev && prev[curr], obj);
    },
  },
};
</script>

<style>
</style>
