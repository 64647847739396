<template>
  <div class="row">
    <div
      :class="[colClass]"
      class="block-dark block-kopf-dark"
      :style="systemColor()"
    >
      <span class="primary-headline-text text-white">{{ headline }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadMenuFilter",
  props: {
    headline: {
      type: String,
      required: true,
    },
    col: {
      type: Number,
      required: false,
      default: 2,
    },
  },
  computed: {
    colClass: function () {
      if (this.col) {
        return "col-xl-" + this.col;
      } else {
        return "col-xl-2";
      }
    },
  },
  methods: {
    systemColor() {
      switch (this.$CONST("CONFIG").SERVERURL) {
        case "https://abbts-test.bx-education.ch/":
          return "background-color: #248C8C";
        case "https://test.bx-education.ch/":
          return "background-color: #248C8C";
        case "https://education-dev.batix.ch/":
          return "background-color: #5F379B";
        case "https://abbts-dev-aws.bx-education.ch/":
          return "background-color: #5F379B";
        default:
          return "background-color: #124862";
      }
    },
  },
};
</script>

<style>
</style>
