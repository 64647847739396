<template>
  <div>
    <container-headline :headline="$t('global.coursedetails')" :col="6"></container-headline>

    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <b-form-input
                  v-model.trim="kurs.kontakt_email"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                />
                <label>{{ $t("global.contactemail") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.ort"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.city") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.dauer"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.duration") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.daten"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.Dates") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.zeiten"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.times") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.treffpunkt"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.meetingpoint") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.kosten"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.costs") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.lehrmittel"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.teachingmaterial") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.dokumentation"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.documentation") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "KursDetails",
  components: {
    ContainerHeadline,
  },
  props: {
    kurs: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss">
</style>
