<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.belegnummer"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.receiptnum") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.belegnummerVon"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.documentnumfrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.belegnummerBis"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.documentnumupto") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="col-xl-12" style="font-size: 14px">
                      <input
                        type="checkbox"
                        :value="true"
                        v-model="filters.hatKostenstelle"
                      />
                      <label class="pl-1">{{
                        $t("global.hascostcenter")
                      }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betragVon"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.amountfrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betragBis"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.amountupto") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-export-von"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.exportVon"
                        field="exportVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.exportfrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-export-bis"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.exportBis"
                        field="exportBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.exportuntil") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['auftrag.nummer']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.ordernum") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.sollkonto"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.debitaccount") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.habenkonto"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.creditaccount") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betragVon"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.amountfrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betragBis"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.amountupto") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-von"
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.erstelldatumVon"
                        field="erstelldatumVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.creationdatefrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-bis"
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.erstelldatumBis"
                        field="erstelldatumBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.creationdateuntil") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="col-xl-12" style="font-size: 14px">
                      <input
                        type="radio"
                        :value="true"
                        v-model="filters.isExported"
                      />
                      <label class="pl-1">{{ $t("global.exported") }}</label>
                      <br />
                      <input
                        type="radio"
                        :value="false"
                        v-model="filters.isExported"
                      />
                      <label class="pl-1">{{ $t("global.notexported") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row pt-2 pb-3" v-if="filters.isExported">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="filters.exportdatum"
                          placeholder=" "
                          :show-icon="true"
                          field="exportdatum"
                          @updatefull="setFilterDate"
                        />
                        <label>{{ $t("global.exportdate") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.belegtyp"
                        multiple
                        :options="belegtypen"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.receipttype')"
                      >
                        <span slot="no-options">{{
                          $t("global.notypefound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.receipttype") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.receipts')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4 mr-2">
                    <button
                      class="btn btn-primary"
                      @click="abacusExport"
                      v-if="berechtigungen.m_finanzen.update"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-file-export"
                        class="mr-2"
                      />
                      {{ $t("global.abacusexport") }}
                    </button>
                  </div>
                  <div class="ml-2">
                    <button
                      class="btn btn-primary"
                      @click="abacusExportohneCheck"
                      v-if="
                        berechtigungen.m_finanzen.update &&
                        angemeldeterMitarbeiter.id == '177F79CFBBD'
                      "
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-file-export"
                        class="mr-2"
                      />
                      {{ $t("global.abacusexportwithoutcheck") }}
                    </button>
                  </div>
                  <div class="ml-2">
                    <button
                      class="btn btn-primary"
                      @click="abacusExportohneCheckMitKS"
                      v-if="
                        berechtigungen.m_finanzen.update &&
                        angemeldeterMitarbeiter.id == '177F79CFBBD'
                      "
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-file-export"
                        class="mr-2"
                      />
                      {{ $t("global.abacusexportwithoutcheckwithkst") }}
                    </button>
                  </div>
                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneExportdatumLoeschenModal"
                      v-if="berechtigungen.m_finanzen.update"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-xmark"
                        class="mr-2"
                      />
                      {{ $t("global.removeexportdate") }}
                    </button>
                  </div>
                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_finanzen.delete"
                      :disabled="isDisabled"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />
                      <span>{{ $t("global.delete") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelectedLocal(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loeschen-modal
      id="belege-loeschen-modal"
      @confirmed="loeschen"
      :multiple="true"
    />
    <alert-modal
      id="belege-exportdatumloeschen-modal"
      @exportdatumloeschen="exportdatumLoeschen"
      :eventName="eventname"
      :text="text"
      :title="title"
    />

    <messagebox-count
      :headerText="$t('notification.receiptsdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import AlertModal from "@/components/Modals/AlertModal";
import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";

import { LADE_AUSWAHLWERTE_BELEG } from "@/store/beleg/actions.type";
import FileDownload from "js-file-download";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import DatePicker from "@/components/Datepicker";
import numeral from "numeral";

export default {
  name: "Belege",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    DatePicker,
    AlertModal,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.receipts");
      },
    };
  },
  mixins: [page, dashboard],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      belegliste: [],
      text: this.$t("global.doyouwantdeleteexportdataofdocuments"),
      title: this.$t("global.deleteexportdata"),
      eventname: "exportdatumloeschen",
      isDisabled: true,
      expordatumLoeschen: false,
      fields: [
        { key: "selected", label: "" },
        {
          key: "belegnummer",
          sortable: true,
          label: this.$t("global.receiptnum"),
        },
        {
          key: "belegtyp.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.receipttype"),
        },
        {
          key: "exportdatum",
          sortable: true,
          label: this.$t("global.exportdate"),
        },
        {
          key: "date",
          sortable: true,
          searchable: true,
          label: this.$t("global.creationdate"),
        },
        {
          key: "auftrag.auftragsnummer",
          sortable: true,
          searchable: true,
          label: this.$t("global.ordernum"),
        },
        {
          key: "sollkonto",
          searchable: false,
          sortable: true,
          label: this.$t("global.debitaccount"),
          formatter: (value) => {
            if (value.kontonummer && value.bezeichnung) {
              return value.kontonummer + " " + value.bezeichnung;
            } else {
              return "-";
            }
          },
        },
        {
          key: "habenkonto",
          searchable: false,
          sortable: true,
          label: this.$t("global.creditaccount"),
          formatter: (value) => {
            if (value) {
              return value.kontonummer + " " + value.bezeichnung;
            } else {
              return "-";
            }
          },
        },
        {
          key: "betrag",
          searchable: true,
          sortable: true,
          label: this.$t("global.amount"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          key: "belegtyp.bezeichnung",
          sortable: true,
          label: this.$t("global.receipttype"),
        },
        {
          key: "exportdatum",
          sortable: true,
          label: this.$t("global.exportdate"),
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      params.filterBelegnr = this.setQueryParam(this.filters.belegnummer);
      params.filterBelegnrVon = this.setQueryParam(this.filters.belegnummerVon);
      params.filterBelegnrBis = this.setQueryParam(this.filters.belegnummerBis);
      params.filterKostenstelle = this.setQueryParam(
        this.filters.hatKostenstelle
      );
      params.filterBetragVon = this.setQueryParam(this.filters.betragVon);
      params.filterBetragBis = this.setQueryParam(this.filters.betragBis);
      params.filterExportVon = this.setQueryParam(this.filters.exportVon);
      params.filterExportBis = this.setQueryParam(this.filters.exportBis);
      params.filterAuftragnr = this.setQueryParam(
        this.filters["auftrag.nummer"]
      );
      params.filterSollkonto = this.setQueryParam(this.filters.sollkonto);
      params.filterHabenkonto = this.setQueryParam(this.filters.habenkonto);
      params.filterBetrag = this.setQueryParam(this.filters.betrag);
      params.filterBetragVon = this.setQueryParam(this.filters.betragVon);
      params.filterBetragBis = this.setQueryParam(this.filters.betragBis);
      params.filterExportdatum = this.setQueryParam(this.filters.exportdatum);
      params.filterDebitor = this.setQueryParam(this.filters.debitor);
      params.filterBelegtyp = this.setQueryParam(this.filters.belegtyp);
      params.filterIsExported = this.setQueryParam(this.filters.isExported);
      params.filterCount = this.setQueryParam(this.filters.count);

      params.filterErstelldatum = this.setQueryParam(this.filters.date);

      if (this.filters.erstelldatumVon && this.filters.erstelldatumVon != "..")
        params.filterErstelldatumVon = this.filters.erstelldatumVon;

      if (this.filters.erstelldatumBis && this.filters.erstelldatumBis != "..")
        params.filterErstelldatumBis = this.filters.erstelldatumBis;

      if (this.belegtyp) params.filterBelegtyp = this.belegtyp;
      else
        params.filterBelegtyp = this.filters.belegtyp
          ? this.filters.belegtyp.join(",")
          : null;

      // TODO
      //params.filterBTBez = this.setQueryParam(this.filters['belegtyp.bezeichnung']);

      return params;
    },
    belegtypen: {
      get() {
        return this.$store.state.beleg.belegtypen;
      },
    },
  },
  created() {
    this.initFilter("belege-liste", "finanzen/beleg/", true);
  },
  mounted() {
    if (this.belegtypen.length == 0)
      this.$store.dispatch(`beleg/${LADE_AUSWAHLWERTE_BELEG}`);

    this.setHeaders("global", [
      "",
      "receiptnum",
      "receipttype",
      "exportdate",
      "creationdate",
      "ordernum",
      "debitaccount",
      "creditaccount",
      "amount",
      "receipttype",
      "exportdate",
    ]);
  },
  methods: {
    abacusExportohneCheckMitKS() {
      if (this.selectedIds.length > 0) {
        if (this.belegliste.length != 0) {
          let exportliste = this.removeDuplicates(
            this.belegliste,
            (item) => item.id
          );
          console.log(exportliste);
          Api.post("fibuexportohnedatum/ks/", exportliste)
            .then((response) => {
              FileDownload(response.data, "Abacus-Export.xml");
              this.$notify({
                type: "success",
                title: this.$t("notification.actionsuccessful"),
                text: this.$t("notification.receiptssuccessfullyexported"),
              });
            })
            .catch((e) => {
              this.$notify(apiErrorToAlert(e));
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text: this.$t("notification.noreceiptsselected"),
          });
        }
      }
      this.selectedIds = [];
      this.belegliste = [];
      this.selectedObjects = [];
    },
    abacusExportohneCheck() {
      if (this.selectedIds.length > 0) {
        if (this.belegliste.length != 0) {
          let exportliste = this.removeDuplicates(
            this.belegliste,
            (item) => item.id
          );
          console.log(exportliste);
          Api.post("fibuexportohnedatum/", exportliste)
            .then((response) => {
              FileDownload(response.data, "Abacus-Export.xml");
              this.$notify({
                type: "success",
                title: this.$t("notification.actionsuccessful"),
                text: this.$t("notification.receiptssuccessfullyexported"),
              });
            })
            .catch((e) => {
              this.$notify(apiErrorToAlert(e));
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text: this.$t("notification.noreceiptsselected"),
          });
        }
      }
      this.selectedIds = [];
      this.belegliste = [];
      this.selectedObjects = [];
    },
    abacusExport() {
      let error = 0;
      let belegnr = 0;
      if (this.selectedIds.length > 0) {
        this.belegliste.forEach(function (beleg) {
          if (!beleg.waehrung?.id) {
            error = 1;
            belegnr = beleg.belegnummer;
          }
          if (!beleg.sollkonto?.id) {
            error = 2;
            belegnr = beleg.belegnummer;
          }
          if (!beleg.habenkonto?.id) {
            error = 3;
            belegnr = beleg.belegnummer;
          }
          if (beleg.exportdatum) {
            error = 4;
            belegnr = beleg.belegnummer;
          }
        });
        if (error == 1) {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text:
              this.$t("notification.currencyundefinedindocumentnum") + belegnr,
          });
          this.selectedIds = [];
          this.selectedObjects = [];
          this.belegliste = [];
          return;
        }
        if (error == 2) {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text:
              this.$t("notification.debitaccountundefineddocumentnum") +
              belegnr,
          });
          this.selectedIds = [];
          this.selectedObjects = [];
          this.belegliste = [];
          return;
        }
        if (error == 3) {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text:
              this.$t("notification.creditaccountundefinedindocumentnum") +
              belegnr,
          });
          this.selectedIds = [];
          this.selectedObjects = [];
          this.belegliste = [];
          return;
        }
        if (error == 4) {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text:
              this.$t("global.receipt") +
              " " +
              belegnr +
              " " +
              this.$t("global.alreadyexported"),
          });
          this.selectedIds = [];
          this.selectedObjects = [];
          this.belegliste = [];
          return;
        }

        if (this.belegliste.length != 0) {
          let exportliste = this.removeDuplicates(
            this.belegliste,
            (item) => item.id
          );
          console.log(exportliste);
          Api.post("fibuexport/", exportliste)
            .then((response) => {
              FileDownload(response.data, "Abacus-Export.xml");
              this.$notify({
                type: "success",
                title: this.$t("notification.actionsuccessful"),
                text: this.$t("notification.receiptssuccessfullyexported"),
              });
            })
            .catch((e) => {
              this.$notify(apiErrorToAlert(e));
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text: this.$t("notification.noreceiptsselected"),
          });
        }
        this.selectedIds = [];
        this.belegliste = [];
        this.selectedObjects = [];
      }
    },

    details(belege) {
      this.$router.push({
        path: "/finanzen/beleg/" + belege.id,
      });
    },

    exportdatumLoeschen() {
      //this.exportdatumLoeschen = true;
      console.log("expDat loeschen: " + this.exportdatumLoeschen);

      Api.put("finanzen/beleg/", {
        data: this.selectedIds,
        datumloeschen: (this.expordatumLoeschen = true),
      })
        .then((response) => {
          this.schliesseExportdatumLoeschenModal();
          this.listData = this.listData.filter(
            (v) => !response.data.success.includes(v.id)
          );
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally((this.exportdatumLoeschen = false));

      this.$refs.selectableTable.refresh();
    },

    removeDuplicates(data, key) {
      return [...new Map(data.map((item) => [key(item), item])).values()];
    },

    korrekturbuchung() {
      this.$router.push({
        path: "/finanzen/beleg/",
      });
    },

    loeschen() {
      this.delete("finanzen/beleg/", this.selectedIds);
    },

    schliesseLoeschenModal() {
      $("#belege-loeschen-modal").modal("hide");
    },

    oeffneLoeschenModal() {
      $("#belege-loeschen-modal").modal("show");
    },
    oeffneExportdatumLoeschenModal() {
      if (this.selectedIds.length > 0) {
        if (
          this.belegliste.filter(function (e) {
            return e.exportdatum !== null;
          }).length > 0
        ) {
          $("#belege-exportdatumloeschen-modal").modal("show");
        } else {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text: this.$t("notification.noexportdataavailable"),
          });
        }
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noreceiptsselected"),
        });
      }
    },
    schliesseExportdatumLoeschenModal() {
      $("#belege-exportdatumloeschen-modal").modal("hide");
      this.selectedIds = [];
      this.belegliste = [];
      this.selectedObjects = [];
    },

    onRowSelectedLocal(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
        this.belegliste.push(row.item);
        this.selectedObjects.push(row.item);

        this.selectedIds = Array.from(new Set(this.selectedIds));
        this.selectedObjects = Array.from(new Set(this.selectedObjects));
        this.belegliste = Array.from(new Set(this.belegliste));

        if (
          this.selectedIds.length != 0 &&
          this.selectedObjects.find((obj) => obj.exportdatum != null) ==
            undefined
        ) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
        this.belegliste = this.belegliste.filter((el) => el.id != row.item.id);
        this.selectedObjects = this.selectedObjects.filter(
          (object) => object != row.item
        );
        if (
          this.selectedIds.length != 0 &&
          this.selectedObjects.find((obj) => obj.exportdatum != null) ==
            undefined
        ) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      }
    },
  },
};
</script>

<style>
</style>
