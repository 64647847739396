<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    class="btn btn-success mr-2"
                    :disabled="!editable || invalid"
                    @click="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button
                    v-if="berechtigungen.m_finanzen.update"
                    :disabled="!id || !editable"
                    class="btn btn-primary mr-2"
                    @click="oeffneSingleSelectModal"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-arrow-alt-from-left"
                      class="mr-2"
                    />
                    {{ $t("global.assignmanually") }}
                  </button>
                  <button
                    v-if="berechtigungen.m_finanzen.update"
                    :disabled="!editable"
                    class="btn btn-primary mr-2"
                    @click="zahlungseingangBuchen(id)"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-sack-dollar"
                      class="mr-2"
                    />
                    {{ $t("global.book") }}
                  </button>
                  <button class="btn btn-primary" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_finanzen.delete"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Stammdaten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-3">
                    <div class="form-group">
                      <input
                        v-model="zahlungseingang.zahlungsnummer"
                        class="form-control"
                        placeholder=" "
                        readonly
                      />
                      <label>{{ $t("global.paymentnumber") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <single-select-debitor
                      id="person-debitor"
                      :debitor="zahlungseingang.zahlschuld"
                      :label="$t('global.debtor')"
                      :allowOpen="true"
                      :editable="editable"
                    />
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <v-select
                          v-model="zahlungseingang.zahlungseingangstatus"
                          label="bezeichnung"
                          v-bind:class="{ 'border-info': !passed }"
                          :options="zahlungseingangstati"
                          :disabled="!editable"
                          :placeholder="$t('global.status')"
                        >
                          <span slot="no-options">{{
                            $t("notification.taptosearch")
                          }}</span>
                        </v-select>
                        <label v-bind:class="{ 'text-danger': !passed }">{{
                          $t("global.incomingpaymentstatus")
                        }}</label>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-2">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="zahlungseingang.valutadatum"
                        placeholer=" "
                        :show-icon="true"
                        :disabled="!editable"
                        @update="(val) => (zahlungseingang.valutadatum = val)"
                      />
                      <label>{{ $t("global.Valuedate") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-3">
                    <div class="form-group">
                      <v-select
                        v-model="zahlungseingang.waehrung"
                        label="bezeichnung"
                        :options="waehrungen"
                        :placeholder="$t('global.currency')"
                        :clearable="false"
                        :disabled="!editable"
                      >
                      </v-select>
                      <label>{{ $t("global.currency") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-2">
                    <div class="form-group">
                      <ValidationProvider
                        rules="required:true|regex:^([0-9]+)\.?[0-9]?[0-9]?$"
                        immediate
                        v-slot="{ passed }"
                      >
                        <input
                          v-model="zahlungseingang.betrag"
                          class="form-control"
                          v-bind:class="{ 'border-info': !passed }"
                          placeholder=" "
                          :readonly="!editable"
                        />
                        <label v-bind:class="{ 'text-danger': !passed }">{{
                          $t("global.amount")
                        }}</label>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <v-select
                        v-model="zahlungseingang.zahlungsverbindung"
                        label="bezeichnung"
                        :options="zahlungsverbindungen"
                        :placeholder="$t('global.paymentdetails')"
                        :clearable="false"
                        :disabled="!editable"
                      >
                      </v-select>
                      <label>{{ $t("global.paymentdetails") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Stammdaten -->
        </div>
        <!-- ENDE linke Seite -->
        <!-- START Kontrolle -->
        <div
          class="col-xl-6 pr-4"
          :class="{ 'col-xl-12': !showNavbar }"
          v-if="zahlungseingang.refrenznummer"
        >
          <!-- START rechte Seite -->
          <container-headline
            :headline="$t('global.control')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model="zahlungseingang.valutadatum"
                        class="form-control"
                        placeholder=" "
                        readonly
                      />
                      <label>{{ $t("global.bookingreference") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model="zahlungseingang.refrenznummer"
                        class="form-control"
                        placeholder=" "
                        readonly
                      />
                      <label>{{ $t("global.referencenum") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model="zahlungseingang.referenzadresse"
                        class="form-control"
                        placeholder=" "
                        readonly
                      />
                      <label>{{ $t("global.addressreference") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model="zahlungseingang.zahlschuld.rechnung_an"
                        class="form-control"
                        placeholder=" "
                        readonly
                      />
                      <label>{{ $t("global.address") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model="zahlungseingang.referenzauftrag"
                        class="form-control"
                        placeholder=" "
                        readonly
                      />
                      <label>{{ $t("global.orderreference") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model="zahlungseingang.zahlschuld.auftrag.nummer"
                        class="form-control"
                        placeholder=" "
                        readonly
                      />
                      <label>{{ $t("global.order") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model="zahlungseingang.mikrofilmnummer"
                        class="form-control"
                        placeholder=" "
                        readonly
                      />
                      <label>{{ $t("global.microfilmnum") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="zahlungseingang.record"
                        class="form-control"
                        placeholder=" "
                        readonly
                      />
                      <label>{{ $t("global.record") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Kontrolle -->
        </div>
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <div id="modal-loeschen" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4>
              <font-awesome-icon
                icon="fa-regular fa-warning"
                class="mr-2 text-warning"
              />{{ $t("global.deletepayment") }}
            </h4>
          </div>
          <div class="modal-body">
            {{ $t("global.doyouwantdeletethepayment") }}
          </div>
          <div class="modal-footer">
            <button class="btn btn-default" @click="schliesseLoeschenModal">
              {{ $t("global.cancel") }}
            </button>
            <button class="btn btn-primary" @click="loeschen">
              {{ $t("global.delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <change-watcher-alert />

    <single-select-zahlungseingang
      :id="id"
      :betrag="betrag"
      @updateZahlungseingang="setDebitor"
    />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";
import DatePicker from "@/components/Datepicker";
import ChangeWatcher from "@/mixins/ChangeWatcher";

import SingleSelectZahlungseingang from "@/pages/Finanzen/Modals/SingleSelectZahlungseingang.vue";
import SingleSelectDebitor from "@/components/global/SingleSelectDebitor.vue";
import { LADE_AUSWAHLWERTE_ZAHLUNGSEINGANG } from "@/store/zahlungseingang/actions.type";
import { LADE_AUSWAHLWERTE_AUFTRAG } from "@/store/auftrag/actions.type";
import { textNotLinked } from "@/defaultTexts.js";
import { apiErrorToAlert } from "@/utils/Errorhandler";

export default {
  name: "Zahlungseingang",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    SingleSelectZahlungseingang,
    SingleSelectDebitor,
    DatePicker,
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      zahlungseingang: {
        zahlungseingangstatus: {},
        zahlschuld: { personName: textNotLinked, auftrag: {} },
        waehrung: {},
        auftrag: {},
        adresse: {},
      },
      betrag: 0,
      loading: false,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.id) {
        return this.zahlungseingang?.zahlungsnummer
          ? `${this.$t("global.incomingpayment")} ${
              this.zahlungseingang.zahlungsnummer
            }`
          : this.$t("global.incomingpayment");
      } else {
        return this.$t("global.newincomingpayment");
      }
    },

    zahlungseingangstati: {
      get() {
        return this.$store.state.zahlungseingang.zahlungseingangstati;
      },
    },
    zahlungsverbindungen: {
      get() {
        return this.$store.state.auftrag.zahlungsverbindungen;
      },
    },
    waehrungen: {
      get() {
        return this.$store.state.auftrag.waehrungen;
      },
    },
  },
  watch: {},
  created() {
    if (this.waehrungen.length == 0)
      this.$store.dispatch(`auftrag/${LADE_AUSWAHLWERTE_AUFTRAG}`);

    if (this.id) {
      this.editable = false;
      Api.get("finanzen/zahlungseingang/", {
        params: { id: this.id },
      }).then((response) => {
        this.initializeZahlung(response.data);
      });
    } else {
      this.zahlungseingang.waehrung = { id: "174E4563A31", bezeichnung: "CHF" }; // TODO: das muss schöner gemacht werden
      this.zahlungseingang.zahlungseingangstatus = {
        bezeichnung: "Unverbucht",
        id: "17569A8AF12",
      }; // TODO: das muss schöner gemacht werden

      this.editable = true;
    }
  },
  mounted() {
    if (this.zahlungseingangstati.length == 0)
      this.$store.dispatch(
        `zahlungseingang/${LADE_AUSWAHLWERTE_ZAHLUNGSEINGANG}`
      );
  },
  methods: {
    zahlungseingangBuchen(selectedId) {
      let selectedIds = [selectedId];
      if (this.zahlungseingang.zahlschuld.id) {
        Api.post("finanzen/zahlungseingang/buchung/", {
          params: selectedIds,
        }).then((response) => {
          if (response.data.errors.length < 1) {
            Api.get("finanzen/zahlungseingang/", {
              params: { id: this.id },
            }).then((response) => {
              this.initializeZahlung(response.data);
            });
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.incomingpaymentposted"),
            });
          } else {
            this.$notify({
              type: "warn",
              title: this.$t("notification.actioncanceled"),
              text: this.$t("notification.errorpostingincomingpayment"),
            });
          }
        });
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actioncanceled"),
          text: this.$t("notification.incomingpaymentnotlinkedtodebtor"),
        });
      }
    },

    setDebitor(zahlschuld) {
      this.zahlungseingang.zahlschuld = zahlschuld;
      this.$notify({
        type: "success",
        title: this.$t("notification.actionsuccessful"),
        text: this.$t("notification.incomingpaymentlinked"),
      });
    },

    oeffneSingleSelectModal() {
      $("#zahlungseingang-zuweisen-modal").modal("show");
      this.$bus.$emit("zahlungseingang-zuweisen-modal", null);
    },
    abbrechen() {
      this.$router.push({ name: "Zahlungen" });
    },
    initializeZahlung(zahlungseingang) {
      if (zahlungseingang) {
        this.zahlungseingang = zahlungseingang;

        if (!zahlungseingang.zahlschuld) {
          this.zahlungseingang.zahlschuld = { id: null };
          this.zahlungseingang.zahlschuld.auftrag = {
            einletungstext: textNotLinked,
          };
        }

        if (!zahlungseingang.adresse)
          this.zahlungseingang.adresse = { strasse_nr: textNotLinked };

        if (!zahlungseingang.auftrag)
          this.zahlungseingang.auftrag = { einletungstext: textNotLinked };

        if (!zahlungseingang.waehrung)
          this.zahlungseingang.waehrung = { bezeichnung: textNotLinked };

        this.betrag = zahlungseingang.betrag;
        this.editable =
          this.zahlungseingang.zahlungseingangstatus.id == "17569A8AF12"
            ? true
            : false;
      } else {
        console.error("Zahlung ist null");
        this.editable = true;
      }

      this.initChangeWatcher(this.zahlungseingang);
    },
    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.zahlungseingang);

      if (this.id) {
        Api.put("finanzen/zahlungseingang/", json, { params: { id: this.id } })
          .then((response) => {
            this.initializeZahlung(response.data);

            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.incomingpaymentsuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.post("finanzen/zahlungseingang/", json, { params: {} })
          .then((response) => {
            this.loading = false;

            this.$router.push({
              name: "Zahlungseingang",
              params: { id: response.data.id },
            });

            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.incomingpaymentsuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          });
      }
    },
    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    loeschen() {
      Api.delete("finanzen/zahlungseingang/", {
        params: { id: this.id },
      }).then(() => {
        this.schliesseLoeschenModal();
        this.$router.push({ name: "Zahlungen" });
      });
    },
  },
};
</script>

<style>
</style>
