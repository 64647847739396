<template>
  <div class="list">
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betreff"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.subject") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.absender"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.sender") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['mailvorlagentyp.bezeichnung']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.mailtemplatetype") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.mailtemplates')"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <router-link
                      class="btn btn-success"
                      :to="{ name: 'mail-vorlage', params: { anzeige: 0 } }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>
                  </div>
                  <div class="mr-4 float-right">
                    <button class="btn btn-danger" @click="oeffneLoeschenModal">
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    tbody-tr-class="item"
                    small
                    :items="mailvorlagen"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    responsive
                    fixed
                    sort-icon-left
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="field.key != 'selected'"
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="row">
                      <b-form-checkbox
                        v-model="row.selected"
                        @input="selectAllRows(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">{{ $t("global.nofurtherdatafound") }}</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="mail-vorlagen-loeschen-modal"
      :selectedIds="selectedIds"
      :multiple="true"
      @confirmed="mailLoeschen"
    />
  </div>
</template>

<script>
//import DatePicker from "@/components/Datepicker";
import Api from "@/Api";
import { debounce } from "@/utils/Debouncer";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";

import store from "@/store";
import page from "@/mixins/Page";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "MailVorlagenListe",
  components: {
    Navbar,
    InfiniteLoading,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.templatesemail");
      },
    };
  },
  mixins: [page],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      page: 0,
      anzahlProPage: 50,
      infiniteId: +new Date(),

      mailvorlagen: [],

      filters: {
        betreff: "",
        ["mailvorlagentyp.bezeichnung"]: "",
        absendername: "",
      },

      sortBy: "bezeichnung",
      sortDesc: false,

      fields: [
        { key: "selected", label: "" },
        { key: "bezeichnung", sortable: true, label: this.$t("global.designation") },
        {
          key: "betreff",
          sortable: true,
          label: this.$t("global.subject"),
          thStyle: { width: "30% !important" },
        },
        {
          key: "mailvorlagentyp.bezeichnung",
          sortable: true,
          label: this.$t("global.mailtemplatetype"),
        },
        { key: "absendername", sortable: true, label: this.$t("global.sender") },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.filters.betreff) params.filterBetreff = this.filters.betreff;
      if (this.filters["mailvorlagentyp.bezeichnung"])
        params.filterTypBez = this.filters["mailvorlagentyp.bezeichnung"];
      if (this.filters.absendername)
        params.filterAbsender = this.filters.absendername;
      if (this.filters.bezeichnung)
        params.filterBezeichnung = this.filters.bezeichnung;

      return params;
    },
  },
  watch: {
    filters: {
      handler: function () {
        this.resetLoading(this);
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    details(mailvorlagen) {
      this.$router.push({
        name: "mail-vorlage",
        params: { id: mailvorlagen.id, anzeige: 0 },
      });
    },

    resetLoading: debounce((self) => {
      self.page = 0;
      self.mailvorlagen = [];
      self.infiniteId += 1;
    }, 500),

    mailLoeschen() {
      Api.delete("mail/vorlagen", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "mail-vorlagen-loeschen-modal");
    },

    infiniteHandler($state) {
      Api.get("/mail/vorlagen/", {
        params: this.queryParams,
      }).then((response) => {
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.mailvorlagen.push(...response.data);
          $state.loaded();
        } else {
          this.mailvorlagen.push(...response.data);
          $state.complete();
        }
      });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>

<style>
</style>
