<template>
  <div>
    <container-headline :headline="$t('global.masterdata')" :col="6"></container-headline>
    <div class="row">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-6">
              <single-select-person
                id="subvention-verantwortlicher"
                :person="subvention.person"
                :label="$t('global.person')"
                :allowOpen="berechtigungen.m_geschaeftspartner.read"
                :editable="editable"
                :required="false"
                @confirmed="setPerson"
              />
            </div>

            <div class="col-xl-6">
              <div class="form-group"></div>
            </div>

            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="subvention.ausbildung"
                  label="bezeichnung"
                  :disabled="!editable"
                  :options="ausbildungen"
                  :placeholder="$t('dashboard.type')"
                >
                  <span slot="no-options">{{ $t("notification.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("dashboard.education") }}</label>
              </div>
            </div>

            <div class="col-xl-6">
              <div class="form-group">
                <input
                  v-model="subvention.ausbildung.abschluss.bezeichnung"
                  readonly
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.degree") }}</label>
              </div>
            </div>

            <div class="col-xl-6">
              <div class="form-group">
                <input
                  v-model="subvention.ausbildung.ausbildungtyp.bezeichnung"
                  readonly
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.educationtype") }}</label>
              </div>
            </div>

            <div class="col-xl-6">
              <div class="form-group">
                <input
                  v-model="subvention.ausbildung.ausbildungdauer.bezeichnung"
                  readonly
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.durationofeducation") }}</label>
              </div>
            </div>

            <div class="col-xl-4">
              <div class="form-group">
                <v-select
                  v-model="subvention.ausbildungstatus"
                  label="bezeichnung"
                  :disabled="!editable"
                  :options="ausbildungstati"
                  :placeholder="$t('global.status')"
                >
                  <span slot="no-options">{{ $t("notification.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.statusofeducation") }}</label>
              </div>
            </div>

            <div class="col-xl-2">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="subvention.ausbildungsbeginn"
                  placeholer=" "
                  :disabled="!editable"
                  :show-icon="true"
                  @update="(val) => (subvention.ausbildungsbeginn = val)"
                />
                <label>{{ $t("global.Start") }}</label>
              </div>
            </div>

            <div class="col-xl-2">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="subvention.ausbildungsende"
                  placeholer=" "
                  :show-icon="true"
                  :disabled="!editable"
                  @update="(val) => (subvention.ausbildungsende = val)"
                />
                <label>{{ $t("global.end") }}</label>
              </div>
            </div>

            <div class="col-xl-4" />

            <div class="col-xl-12">
              <div class="form-group">
                <textarea
                  v-model="subvention.bemerkung"
                  class="form-control"
                  :readonly="!editable"
                  placeholder=" "
                />
                <label>{{ $t("global.comment") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

import { LADE_AUSWAHLWERTE_SUBVENTION } from "@/store/subvention/actions.type";

export default {
  name: "SubventionStammdaten",
  components: {
    ContainerHeadline,
    DatePicker,
    SingleSelectPerson,
  },
  props: {
    subvention: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    ausbildungen: {
      get() {
        return this.$store.state.subvention.ausbildungen;
      },
    },
    ausbildungstati: {
      get() {
        return this.$store.state.subvention.ausbildungstati;
      },
    },
  },
  mounted: function () {
    if (this.ausbildungstati.length == 0)
      this.$store.dispatch(`subvention/${LADE_AUSWAHLWERTE_SUBVENTION}`);
  },
  methods: {
    setPerson(person) {
      this.subvention.person = person;
    },
  },
};
</script>
<style scoped></style>
