<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filterObject.bildschirm"
                        :options="bildschirmListe"
                        v-on:change="changeRoute()"
                        :reduce="(s) => s.eventId"
                        :key="filterObject.bildschirm"
                        label="title"
                        :placeholder="$t('global.screen')"
                      >
                        <span slot="no-options">{{ $t("global.nostatusfound") }}</span>
                      </v-select>
                      <label>{{ $t("global.screen") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="termin-dozent"
                      :person="filterObject.dozent"
                      :label="$t('global.Lecturer')"
                      :allowOpen="false"
                      :rollen="filterRollen"
                      :editable="true"
                      :tabindex="3"
                      @confirmed="setFilterDozent"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.calendar')"
            ></head-menu>

            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex">
                  <div class="ml-4">
                    <router-link
                      v-if="berechtigungen.m_ressourcen.create"
                      class="btn btn-success"
                      :to="{ name: 'screenevent', params: { anzeige: 0 } }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>
                  </div>
                  <div class="ml-2">
                    <router-link
                      v-if="berechtigungen.m_ressourcen.create"
                      class="btn btn-primary"
                      :to="{
                        name: 'standardevent',
                        params: { id: '17E23DA39AC', anzeige: 0 },
                      }"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-calendar-lines-pen"
                        class="mr-2"
                      />
                      {{ $t("global.standardevent") }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="row block belegliste">
              <div class="w-100-p">
                <div style="height: 80vh">
                  <calendar-view
                    :show-date="showDate"
                    :items="items"
                    showTimes
                    :timeFormatOptions="{
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    }"
                    class="
                      theme-default
                      holiday-us-traditional holiday-us-official
                    "
                    @click-item="terminOeffnen"
                  >
                    <calendar-view-header
                      slot="header"
                      slot-scope="t"
                      :header-props="t.headerProps"
                      @input="setShowDate"
                    />
                  </calendar-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";

import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
// The next two lines are processed by webpack. If you're using the component without webpack compilation,
// you should just create <link> elements for these. Both are optional, you can create your own theme if you prefer.
require("vue-simple-calendar/static/css/default.css");
require("vue-simple-calendar/static/css/holidays-us.css");

import { parseDateTimeString } from "@/utils/bxchTools.js";

import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

export default {
  name: "Bildschirmplaner",
  components: {
    HeadMenu,
    HeadMenuFilter,
    Navbar,
    CalendarView,
    CalendarViewHeader,
    SingleSelectPerson,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.screenplanner");
      },
    };
  },
  mixins: [page],
  store,
  props: {},
  data() {
    return {
      items: [],
      bildschirmListe: [],
      showDate: new Date(),
      filterRollen: this.$CONST("ROLLEN").DOZENTEN,
      filterObject: {
        bildschirm: null,
      },
    };
  },
  watch: {
    filterObject: {
      handler: function () {
        console.log("bla");
        this.ladeEvents(this.showDate);
      },
      deep: true,
    },
    showDate: {
      handler: function (val) {
        this.ladeEvents(val);
      },
    },
  },

  computed: {
    selectUpdate() {
      return this.filterObject.bildschirm;
    },

    queryParams: function () {
      var params = {
        bildschirmId: this.setQueryParam(this.filterObject.bildschirm),
        dozentFilter: this.setQueryParam(this.filterObject.dozent?.id),
      };

      //params.bildschirmId = this.filterObject.bildschirm;
      return params;
    },
  },
  created() {
    if (this.bildschirmListe.length == 0) this.ladeBildschirme();
  },
  mounted: function () {
    this.ladeEvents(this.showDate);
  },
  methods: {
    changeRoute() {
      console.log("change route");
    },

    setQueryParam(value) {
      return value && value != "" ? value : null;
    },

    setShowDate(d) {
      this.showDate = d;
    },

    setFilterDozent(dozent) {
      this.$set(this.filterObject, "dozent", dozent);
    },

    setVeranstaltung(veranstaltung) {
      this.filterObject.veranstaltung = veranstaltung;
    },

    /*setBildschirm(bildschirm) {
      this.filterObject.bildschirm = bildschirm;
    },*/

    terminOeffnen(calendarItem) {
      console.log(calendarItem);
      this.$router.push({
        name: "screenevent",
        params: { id: calendarItem.id, anzeige: 0 },
      });
    },
    neuesEvent(calendarItem) {
      console.log(calendarItem);
      this.$router.push({
        name: "screenevent",
        params: { anzeige: 0 },
      });
    },

    ladeEvents(datum) {
      this.loading = true;

      var lastDay = new Date(datum.getFullYear(), datum.getMonth() + 1, 0);
      this.queryParams.vonFilter =
        "1." + (datum.getMonth() + 1) + "." + datum.getFullYear();
      this.queryParams.bisFilter =
        lastDay.getDate() +
        "." +
        (datum.getMonth() + 1) +
        "." +
        datum.getFullYear();

      // params.id = this.filterObject.bildschirm?.eventId;

      Api.get("/bildschirmloesung/", {
        params: this.queryParams,
      })
        .then((response) => {
          this.items = [];
          response.data.forEach((screenEvent) => {
            this.items.push({
              id: screenEvent.id,
              eventId: screenEvent.id,
              startDate: parseDateTimeString(
                screenEvent.startdatum,
                screenEvent.startzeit
              ),
              endDate: parseDateTimeString(
                screenEvent.enddatum,
                screenEvent.endzeit
              ),
              title: screenEvent.bezeichnung,
              style: "font-size: 0.8em; cursor: pointer",
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    ladeBildschirme() {
      this.loading = true;

      var params = null;

      Api.get("/bildschirm/", {
        params: params,
      })
        .then((response) => {
          this.bildschirmListe = [];
          response.data.forEach((screen) => {
            this.bildschirmListe.push({
              id: screen.id,
              eventId: screen.ID,
              title: screen.bezeichnung,
              style: "font-size: 0.8em; cursor: pointer",
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

