<template>
  <div class="list">
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-if="rollen"
                        v-model="filters.rolle"
                        :reduce="(r) => r.id"
                        :options="rollen"
                        label="bezeichnung"
                        :placeholder="$t('global.personstype')"
                        multiple
                      >
                        <span slot="no-options">{{ $t("global.notypefound") }}</span>
                      </v-select>
                      <label>{{ $t("global.typeofperson") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.adressverwendungstyp"
                        :reduce="(advt) => advt.id"
                        :options="adressverwendungstypen"
                        label="bezeichnung"
                        placeholder="Adressverwendung"
                      >
                        <span slot="no-options">{{ $t("global.notypefound") }}</span>
                      </v-select>
                      <label>{{ $t("global.addressusage") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.firma"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.company") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['person.name']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.name") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['person.vorname']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.firstname") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.zip"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.zipcode") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.ort"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.city") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              headline="Adressliste"
              :date="'Mittwoch 29.Juli'"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between" />
              </div>
            </div>

            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    sort-icon-left
                    no-local-sorting
                    @sort-changed="sortingChanged"
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="field.key != 'selected'"
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoreaddresses") }}</div>
                    <div slot="no-more">{{ $t("global.nomoreaddresses") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtheraddressesfound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";

import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";

import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";

export default {
  name: "AdressenListe",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.addresses");
      },
    };
  },
  mixins: [page, dashboard],
  props: {},
  store,
  data() {
    return {
      sortBy: "firma",
      sortDesc: false,
      fields: [
        { key: "selected", label: "" },
        { key: "firma", sortable: true, label: this.$t("global.company") },
        { key: "person.name", sortable: true, label: this.$t("global.name") },
        { key: "person.vorname", sortable: true, label: this.$t("global.firstname") },
        { key: "adresszusatz", sortable: true, label: this.$t("global.addresssuffix") },
        { key: "strasse_nr", sortable: true, label: this.$t("global.street") },
        { key: "plz", sortable: true, label: this.$t("global.zipcode") },
        { key: "ort", sortable: true, label: this.$t("global.city") },
        { key: "land.name", sortable: true, label: this.$t("global.country") },
      ],
    };
  },
  computed: {
    rollen: {
      get() {
        return this.$store.state.geschaeftspartner.partnerrollen;
      },
    },
    adressverwendungstypen: {
      get() {
        return this.$store.state.geschaeftspartner.adressverwendungstypen;
      },
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.sortField) {
        params.sdir = this.sortDir;
        params.sfield = this.sortField;
      }

      params.zip = this.setQueryParam(this.filters.plz);
      params.ort = this.setQueryParam(this.filters.ort);
      params.firma = this.setQueryParam(this.filters.firma);
      params.adresszusatz = this.setQueryParam(this.filters.adresszusatz);
      params.strasse_nr = this.setQueryParam(this.filters.strasse_nr);
      params.name = this.setQueryParam(this.filters["person.name"]);
      params.vorname = this.setQueryParam(this.filters["person.vorname"]);
      params.land = this.setQueryParam(this.filters["land.name"]);
      params.typ = this.setQueryParam(this.filters.adressverwendungstyp);

      params.ip = true;
      params.aec = true;

      params.rollen = this.filters.rolle ? this.filters.rolle.join(",") : null;

      params.filterCount = this.setQueryParam(this.filters.count);

      return params;
    },
  },
  created() {
    if (this.rollen.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );

    this.initFilter("adressen-liste", "geschaeftspartner/adresse/", true);
  },

  mounted() {
    this.setHeaders("global", [
      "",
      "company",
      "name",
      "firstname",
      "addresssuffix",
      "street",
      "zipcode",
      "city",
      "country",
    ]);
  },
  methods: {
    details(adresse) {
      this.$router.push({
        name: "geschaeftspartner",
        params: { id: adresse.person.ID, anzeige: 0 },
      });
    },

    sortingChanged(ctx) {
      this.sortDir = ctx.sortDesc;
      this.sortField = ctx.sortBy;
      this.resetLoading(this);
    },
  },
};
</script>

<style>
</style>
