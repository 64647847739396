<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon
                      icon="fa-solid fa-list"
                      class="mr-2"
                    />{{ $t("global.tolist") }}
                  </button>
                </div>
                <div class="mr-4">
                  <button class="btn btn-danger" @click="oeffneLoeschenModal">
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <mail-vorlage-stammdaten
          :navbarTitel="navbarTitel"
          :mail="mail"
          :editable="editable"
        />

        <mail-vorlage-inhalt
          :navbarTitel="navbarTitel"
          :mail="mail"
          :editable="editable"
        />
      </div>
    </ValidationObserver>

    <loeschen-modal
      id="mail-vorlage-loeschen-modal"
      @confirmed="mailLoeschen"
    />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import Api from "@/Api";
import store from "@/store";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import MailVorlageStammdaten from "@/components/Kommunikation/MailVorlageStammdaten.vue";
import MailVorlageInhalt from "@/components/Kommunikation/MailVorlageInhalt.vue";

export default {
  name: "Mail",
  components: {
    HeadMenu,
    Navbar,
    LoeschenModal,
    MailVorlageStammdaten,
    MailVorlageInhalt,
  },
  mixins: [page],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mail: { zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()) },
      editable: true,
      loading: false,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.id) {
        return this.$t("global.mailtemplates");
      } else {
        return this.$t("global.newmailtemplate");
      }
    },
  },
  watch: {},

  created() {
    if (this.id)
      Api.get("/mail/vorlagen/", { params: { id: this.id } }).then(
        (response) => {
          this.initializeMail(response.data);
        }
      );
  },

  mounted: function () {},

  methods: {
    abbrechen() {
      this.$router.push({ name: "mail-vorlagen-liste" });
    },

    initializeMail(mail) {
      if (mail) {
        this.mail = mail;
      } else {
        console.error("mail vorlage ist null");
      }
    },

    mailLoeschen() {
      Api.delete("/mail/vorlagen/", {
        params: { id: this.id },
      }).then(() => {
        this.schliesseLoeschenModal();
        this.$router.push({ name: "mail-vorlagen-liste" });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "mail-vorlage-loeschen-modal");
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.mail);

      if (!this.id) {
        Api.post("/mail/vorlagen/", json)
          .then((response) => {
            this.initializeMail(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.mailtemplatesuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("/mail/vorlagen/", json, { params: { id: this.mail.id } })
          .then((response) => {
            this.initializeMail(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.mailtemplatesuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    sendTestMail() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.mail);

      Api.put("mail/send/", json, {
        params: { id: this.id, testmail: true },
      })
        .then((response) => {
          this.initializeMail(response.data);

          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.testmailssentsuccessfully")
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>
