<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline class="col-xl-12" :headline="$t('global.fee')" :col="3">
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-primary"
              @click="ausgebenHonorarabrechnung"
              v-if="berechtigungen.m_finanzen.read"
            >
              <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
              <span>{{ $t("global.feestatement") }}</span>
            </button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="berechtigungen.b_finanzen_honorar.delete"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="honorarliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              @row-dblclicked="details"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}n</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="honorar-loeschen-modal"
      @confirmed="honorarloeschen"
      :selectedIds="selectedIds"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import reiter from "@/mixins/Reiter";

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    dozenteneinsatz: {
      type: String,
      required: true,
    },
    person: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 100,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      honorarliste: [],

      fields: [
        { key: "selected", label: "" },
        { key: "honorarnummer", sortable: true, label: this.$t("global.number") },
        { key: "bezeichnung", sortable: true, label: this.$t("global.designation") },
        { key: "honorarstatus.bezeichnung", sortable: true, label: this.$t("global.status") },
        {
          key: "abrechnungsperiode.bezeichnung",
          sortable: true,
          label: this.$t("global.billingperiod"),
        },
        { key: "lohnart.bezeichnung", sortable: true, label: this.$t("global.wagetype") },
        { key: "honorardatum", sortable: true, label: this.$t("global.date") },
        { key: "betrag", sortable: true, label: this.$t("global.amount") },
        { key: "empfaenger.personName", sortable: true, label: this.$t("global.receiver") },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
  },
  created() {},
  mounted() {
    if (this.shown && this.honorarliste.length == 0) this.getHonorar();
    this.setHeaders("global", [
      "",
      "number",
      "designation",
      "status",
      "billingperiod",
      "wagetype",
      "date",
      "amount",
      "receiver",
    ]);
  },
  watch: {
    shown(val) {
      if (val && this.honorarliste.length == 0) this.getHonorar();
    },
  },
  methods: {
    getHonorar() {
      this.isBusy = true;

      Api.get("/finanzen/honorar/", {
        params: {
          dozenteneinsatz: this.dozenteneinsatz,
          person: this.person,
        },
      })
        .then((response) => {
          //response.data.shift();
          this.honorarliste = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(honorarliste) {
      this.$router.push({
        name: "honorar",
        params: { id: honorarliste.id, anzeige: 0 },
      });
    },
    ausgebenHonorarabrechnung() {
      window.open(
        process.env.VUE_APP_SERVER_URL +
          `/pdf/honorarabrechnung-honorar/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "honorar-loeschen-modal");
    },

    honorarloeschen() {
      Api.delete("/finanzen/honorar/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getHonorar();
      });
    },

    infiniteHandler($state) {
      console.log("init");
      Api.get("honorarliste/", {
        params: this.queryParams,
      }).then((response) => {
        console.log("final");
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.honorarliste.push(...response.data);
          $state.loaded();
        } else {
          this.honorarliste.push(...response.data);
          $state.complete();
        }
      });
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>
