<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filterBezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filterCount"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.itemlist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4 mr-2">
                    <router-link
                      :to="{
                        name: 'artikel',
                        params: { anzeige: 0 },
                      }"
                      tag="button"
                      class="btn btn-success"
                      ><font-awesome-icon icon="fa-regular fa-plus" /> 
                      {{ $t("global.newitem") }}</router-link
                    >
                  </div>
                  <div class="mr-4">
                    <button class="btn btn-danger" @click="oeffneLoeschenModal">
                      <font-awesome-icon
                        class="mr-1"
                        icon="fa-duotone fa-trash"
                      />
                      <span>{{ $t("global.delete") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :filter="filterBezeichnung"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="openArtikel"
                  >
                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">{{ $t("global.nofurtherdatafound") }}</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <messagebox-count
      :headerText="$t('global.itemdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
            :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <loeschen-modal
      id="artikel-loeschen-modal"
      @confirmed="loeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { debounce } from "@/utils/Debouncer";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import InfiniteLoading from "vue-infinite-loading";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import { LADE_AUSWAHLWERTE_ARTIKEL } from "@/store/artikel/actions.type";
import { SET_LISTEN_FILTER } from "@/store/dashboard/actions.type";

export default {
  name: "Lernzielvereinbarungsliste",
  components: {
    Navbar,
    InfiniteLoading,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.learningobjectivesagreement");
      },
    };
  },
  mixins: [page, dashboard],
  props: {},
  store,
  data() {
    return {
      page: 0,
      listData: [],
      anzahlProPage: 50,
      anzahlDaten: null,
      anzahlDatenFilter: null,
      filterBezeichnung: null,
      filterCount: null,
      infiniteId: +new Date(),
      sortDesc: false,
      fields: [
        { key: "selected", label: "" },
        { key: "bezeichnung", sortable: true, label: this.$t("global.designation") },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      params.filterBezeichnung = this.filterBezeichnung;
      if (this.filterCount != "") params.filterCount = this.filterCount;

      return params;
    },
  },
  watch: {
    filterBezeichnung: function () {
      this.resetLoading(this);
    },
    filterCount: function () {
      this.resetLoading(this);
    },
  },
  created() {},
  mounted() {
    let state = this.$store.state.dashboard.list["lernzielvereinbarungs-liste"];

    if (state) {
      this.filterBezeichnung = state.bezeichnung;
    }
    this.setHeaders("global", [
      "",
      "designation",
    ]);
  },
  methods: {
    openArtikel(artikel) {
      this.storeFilter();

      this.$router.push({
        name: "artikel",
        params: { artikelid: artikel.id, anzeige: 0 },
      });
    },

    resetLoading: debounce((self) => {
      self.page = 0;
      self.artikel = [];
      self.infiniteId += 1;
    }, 500),

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "artikel-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noitemselected"),
        });
      }
    },

    loeschen() {
      this.delete("artikel/", this.selectedIds);
    },

    storeFilter() {
      this.$store.dispatch(`dashboard/${SET_LISTEN_FILTER}`, {
        list: "lernzielvereinbarungs-liste",
        state: {
          bezeichnung: this.filterBezeichnung,
          kurzbezeichnung: this.filterKurzbezeichnung,
        },
      });
    },

    infiniteHandler($state) {
      Api.get("artikel/", {
        params: this.queryParams,
      }).then((response) => {
        //this.anzahlDaten = response.data[0].count;
        //response.data.shift();
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.listData.push(...response.data);
          this.anzahlDatenFilter = this.listData.length;
          $state.loaded();
        } else {
          this.listData.push(...response.data);
          this.anzahlDatenFilter = this.listData.length;
          $state.complete();
        }
      });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>

<style>
</style>
